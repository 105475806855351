export class Storage<T> {
    protected type: { new(): T; };
    protected className: string;

    constructor(t: { new(): T; }, name?: string) {
        this.type = t;
        if (name) {
            this.className = name;
        } else {
            this.className = t.name;
        }
    }

    setObject(object: T): void {
        const jsonData = JSON.stringify(object);
        localStorage.setItem(this.className, jsonData);
    }

    getObject(): T {
        const storageData = localStorage.getItem(this.className);
        const data = storageData ? JSON.parse(storageData) : null ;
        const newObject = new this.type();

        Object.assign(newObject as any, data);

        return newObject;
    }

    hasObject(): boolean {
        return localStorage.getItem(this.className) !== null;
    }

    deleteObject(): void {
        localStorage.removeItem(this.className);
    }
}
