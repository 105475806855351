import {Injectable} from "@angular/core";
import {HttpService} from "../http/http.service";
import {iResponse, iResponseList} from "../../models/response/response.model";
import {ComponentModel} from "../../models/component/component.model";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class ComponentService extends HttpService {

    getComponents(): Observable<iResponseList<ComponentModel>> {
        return this.get('component').pipe(
            map((response: iResponseList<ComponentModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    getComponentByOrderAndShaft(orderNumber: string, shaftNumber: string): Observable<iResponse<ComponentModel>> {
        return this.get(`component/${orderNumber}/${shaftNumber}`).pipe(
            map((response: iResponse<ComponentModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }
}
