import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {WorkflowService} from "../../../services/workflow/workflow.service";
import {CategoryService} from "../../../services/category/category.service";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {CategoryModel} from "../../../models/category/category.model";
import {iResponse, iResponseList} from "../../../models/response/response.model";
import {WorkflowModel} from "../../../models/workflow/workflow.model";
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {DraftService} from "../../../services/draft/draft.service";
import {DraftModel} from "../../../models/draft/draft.model";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {RouterLink} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {CanDeactivateType} from "../../../guards/unsaved-changes/unsaved-changes.guard";
import {AddContentModalComponent} from "../add-content-modal/add-content-modal.component";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";
import {MatSelectModule} from "@angular/material/select";
import {SubcategoryModel} from "../../../models/subcategory/subcategory.model";
export interface NestedCategories {
    category: CategoryModel
    subCategories: SubcategoryModel[]
}
@Component({
    selector: 'app-draft',
    standalone: true,
    imports: [ReactiveFormsModule, CdkDropList, CdkDrag, RouterLink, RoundedButtonComponent, MatSelectModule],
    templateUrl: './draft.component.pug',
    styleUrl: './draft.component.sass'
})
export class DraftComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    draftForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
        uuid: new FormControl<string>(''),
    })

    categoryForm: FormGroup = new FormGroup<any>({
        uuid: new FormControl<string>(''),
    })

    subcategoryForm: FormGroup = new FormGroup<any>({
        uuid: new FormControl<string>(''),
    })

    categories: CategoryModel[] = [];
    nestedCategories: NestedCategories[] = [];
    drafts: DraftModel[] = [];
    filteredDraftList: DraftModel[] = [];
    workflows: WorkflowModel[] = [];
    workflowsCopy: WorkflowModel[] = [];
    selectedWorkflows: WorkflowModel[] = [];
    draftModelWorkflowCopy: WorkflowModel[] = [];

    filterForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
    })
    filterTimeout: any;

    addDraftBool: boolean = false;
    addWorkflowBool: boolean = false;

    draftModel: DraftModel = {
        uuid: '',
        name: '',
        deletedAt: null,
        workflows: [],
        workflowUuids: [],
    }

    constructor(
        private categoryService: CategoryService,
        private infoMessageService: InfoMessageService,
        private workflowService: WorkflowService,
        private draftService: DraftService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.loadCategoryList();
        this.loadDraftList();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    activateAddDraft() {
        this.addDraftBool = !this.addDraftBool!;

        if (!this.addDraftBool) {
            this.resetDraft();
        }
    }

    openAddContentModal() {
        const dialogRef = this.dialog.open(AddContentModalComponent, {
            data: {
                title: 'Neue Vorlage',
                message: 'Bitte geben Sie einen Namen ein',
                placeholder: 'Vorlagenname',
                type: 'draft',
            }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.submitDraft(result.selectedOption);
            }
        })
    }

    activateAddWorkflow() {
        this.addWorkflowBool = !this.addWorkflowBool;

        if (!this.addWorkflowBool) {
            this.categoryForm.get('uuid')?.patchValue('')
            this.workflows = [];
        }
    }

    filterList(listToFilter: DraftModel[]) {
        const filterValue = this.filterForm.get('name')?.value.toLowerCase();
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            this.filteredDraftList = listToFilter.filter(draft => draft.name.toLowerCase().includes(filterValue));
        }, 500)
    }

    activateEditDraft(draft: DraftModel) {
        this.addWorkflowBool = false;
        this.categoryForm.get('uuid')?.patchValue('');
        if (this.draftForm.get('uuid')?.value !== draft.uuid) {
            this.draftForm.get('uuid')?.patchValue(draft.uuid);
            this.draftForm.get('name')?.patchValue(draft.name);
            this.loadDraftById(draft.uuid);
        } else {
            this.draftForm.get('uuid')?.patchValue('');
            this.draftForm.get('name')?.patchValue('');
        }
    }

    updateDraft() {
        this.draftModel.workflowUuids = this.draftModel.workflows.map(workflow => workflow.uuid);
        this.draftModel.name = this.draftForm.get('name')?.value;
        this.draftModel.uuid = this.draftForm.get('uuid')?.value;
        this.draftService.updateDraft(this.draftModel)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    let foundIndex = this.drafts.findIndex(draft => draft.uuid === this.draftModel.uuid);
                    if (foundIndex !== -1) {
                        this.drafts[foundIndex] = response.data;
                    }
                    this.infoMessageService.createMessage('Die Vorlage ' + this.draftModel.name + ' wurde aktualisiert');
                    this.resetDraft();
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    triggerDeleteDraft(draft: DraftModel) {
        this.openConfirmDialog('Löschen', 'Wollen Sie die Vorlage "' + draft.name + '" wirklich löschen?', draft, 'delete')
    }

    isSelected(workflow: WorkflowModel, workFlowArray: WorkflowModel[]): boolean {
        return workFlowArray.some(selectedWorkflow => selectedWorkflow.uuid === workflow.uuid);
    }

    drop(event: CdkDragDrop<WorkflowModel[]>, workflows: WorkflowModel[]) {

        moveItemInArray(workflows, event.previousIndex, event.currentIndex);
        workflows.forEach((wfl, index) => {

            wfl.sortIndex = index;
        })
    }

    loadWorkflows(workflowArray: WorkflowModel[]) {
        if (this.subcategoryForm.get('uuid')?.value === '') {
            this.workflows = [];
            return;
        }

        this.workflowService.getWorkflowListBySubCategory(this.subcategoryForm.get('uuid')?.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<WorkflowModel>) => {
                    if (workflowArray && workflowArray.length > 0) {
                        const selectedWorkflows = response.data.filter(workflow => this.isSelected(workflow, workflowArray));
                        this.workflows = response.data.filter(workflow => !selectedWorkflows.includes(workflow));
                    } else {
                        this.workflows = response.data;
                    }
                    this.workflowsCopy = [...response.data];
                    this.setPicturesNull();

                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    loadDraftList() {
        this.draftService.getDrafts()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<DraftModel>) => {
                    this.drafts = response.data;
                    this.filteredDraftList = [...this.drafts];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    loadDraftById(uuid: string) {
        this.draftService.getDraft(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponse<DraftModel>) => {
                    this.draftModel = response.data;
                    if (!this.draftModel.workflows) {
                        this.draftModel.workflows = [];
                    } else {
                        this.draftModel.workflows.forEach(wfl => {
                            wfl.picture = null;
                        })
                        this.draftModelWorkflowCopy = [...this.draftModel.workflows];
                    }
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    selectWorkflow(workflow: WorkflowModel, workflowArray: WorkflowModel[]) {
        workflow.picture = null;
        const found = workflowArray.find(w => w.uuid === workflow.uuid);
        if (!found) {
            workflowArray.push(workflow);
            const index = this.workflows.findIndex(w => w.uuid === workflow.uuid);
            this.workflows.splice(index, 1);
        } else {
            const index = workflowArray.findIndex(w => w.uuid === workflow.uuid);
            workflowArray.splice(index, 1);
            const foundIndexInCopy = this.workflowsCopy.findIndex(w => w.uuid === workflow.uuid);
            if (foundIndexInCopy !== -1) {
                this.workflows.splice(foundIndexInCopy, 0, workflow);
            }
        }

        workflowArray.forEach((wfl, index) => {
            wfl.sortIndex = index;
        })
    }

    submitDraft(name: string) {
        this.draftModel = {
            uuid: '',
            name: name,
            workflowUuids: this.selectedWorkflows.map(workflow => workflow.uuid),
            workflows: this.selectedWorkflows,
            deletedAt: null,
        }
        this.saveDraft(this.draftModel);
    }

    resetDraft() {
        this.draftForm.reset();
        this.draftForm.get('name')?.setValue('');
        this.categoryForm.get('uuid')?.setValue('');
        this.draftForm.get('uuid')?.setValue('');
        this.selectedWorkflows = [];
        this.addDraftBool = false;
        this.workflows = [];
        this.draftModelWorkflowCopy = [];
        this.draftModel = {
            uuid: '',
            name: '',
            workflowUuids: [],
            workflows: [],
            deletedAt: null
        }
    }

    private saveDraft(draft: DraftModel) {
        this.draftService.addDraft(draft)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponse<DraftModel>) => {
                    this.resetDraft();
                    this.drafts.push(response.data);
                    this.filteredDraftList = [...this.drafts];
                    this.infoMessageService.createMessage('Die Vorlage wurde gespeichert');
                    this.scrollToBottom();
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error);
                }
            })
    }

    private loadCategoryList() {
        this.categoryService.getCategories()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<CategoryModel>) => {
                    this.categories = response.data;

                    this.categories.forEach(category => {
                        this.loadSubcategoriesByCategoryUuid(category)
                    })

                },
                error: (error) => {
                    this.infoMessageService.createMessage(error);
                }
            })
    }

    loadSubcategoriesByCategoryUuid(category: CategoryModel) {
        const uuid = category.uuid;
        if (!uuid) {

            return;
        }

        this.categoryService.getSubCategories(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<SubcategoryModel>) => {
                    this.nestedCategories.push({
                        category: category,
                        subCategories: response.data
                    })
                },
                error: error => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    private openConfirmDialog(title: string, message: string, draft: DraftModel, type: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: title,
                message: message
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                switch (type) {
                    case 'delete':
                        this.deleteDraftTrigger(draft);
                        break;
                    case 'restore':
                        break;
                    default:
                        break;
                }
            }
        });
    }

    private deleteDraftTrigger(draft: DraftModel) {
        this.draftService.deleteDraft(draft.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponse<DraftModel>) => {
                    const index = this.drafts.findIndex(d => d.uuid === draft.uuid);
                    this.drafts.splice(index, 1);
                    this.filteredDraftList = [...this.drafts];
                    this.infoMessageService.createMessage('Die Vorlage ' + draft.name + ' wurde gelöscht');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error);
                }
            })
    }

    private scrollToBottom() {
        setTimeout(() => {
            const element = document.querySelector('section');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        }, 500)
    }

    // CAN DEACTIVATE
    canDeactivate(): CanDeactivateType {
        const areWorkflowsEqual = this.areWorkflowsEqual(this.draftModelWorkflowCopy, this.draftModel.workflows);
        if (this.draftForm.dirty || !areWorkflowsEqual) {
            const deactivateSubject = new Subject<boolean>();
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                data: {
                    title: 'Ungespeicherte Daten',
                    message: 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Nicht gespeicherte Daten gehen verloren.'
                }
            })

            dialogRef.afterClosed().subscribe(result => {
                deactivateSubject.next(result);
            });
            return deactivateSubject;
        } else {
            return true;
        }
    }
    private areWorkflowsEqual(workflowsA: WorkflowModel[], workflowsB: WorkflowModel[]): boolean {
        const uuidsA = workflowsA.map(w => w.uuid).sort();
        const uuidsB = workflowsB.map(w => w.uuid).sort();
        return JSON.stringify(uuidsA) === JSON.stringify(uuidsB);
    }

    private setPicturesNull() {
        this.workflows.forEach(wfl => {
            wfl.picture = null;
        })
        this.workflowsCopy.forEach(wfl => {
            wfl.picture = null;
        })
    }

}
