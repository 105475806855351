import {Injectable} from "@angular/core";
import {HttpService} from "../http/http.service";
import {iResponse, iResponseList} from "../../models/response/response.model";
import {DraftModel} from "../../models/draft/draft.model";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class DraftService extends HttpService {

    getDrafts(): Observable<iResponseList<DraftModel>> {
        return this.get('draft').pipe(
            map((response: iResponseList<DraftModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    getDraftsWhereWorkflowsExist(): Observable<iResponseList<DraftModel>> {
        return this.get('draft/where-workflow-exists').pipe(
            map((response: iResponseList<DraftModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    getDraft(uuid: string): Observable<iResponse<DraftModel>> {
        return this.get(`draft/${uuid}`).pipe(
            map((response: iResponse<DraftModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    addDraft(data: DraftModel): Observable<iResponse<DraftModel>> {
        return this.post('draft', data).pipe(
            map((response: iResponse<DraftModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    deleteDraft(uuid: string): Observable<iResponse<DraftModel>> {
        return this.delete(`draft/${uuid}`).pipe(
            map((response: iResponse<DraftModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    deleteDraftFinal(uuid: string): Observable<iResponse<DraftModel>> {
        return this.delete(`draft/${uuid}/delete`).pipe(
            map((response: iResponse<DraftModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    updateDraft(data: DraftModel): Observable<iResponse<DraftModel>> {
        return this.put('draft', data).pipe(
            map((response: iResponse<DraftModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getDeletedDrafts(): Observable<iResponseList<DraftModel>> {
        return this.get('draft/deleted').pipe(
            map((response: iResponseList<DraftModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    checkDuplicates(name: string, uuid?: string):Observable<iResponse<boolean>> {
        return this.put(`draft/check-duplicates`, {name}).pipe(
            map((response: iResponse<boolean>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }
}
