import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener, NgZone,
    OnDestroy,
    ViewChild
} from '@angular/core';
import {
    NgxScannerQrcodeComponent,
    NgxScannerQrcodeModule,
    ScannerQRCodeConfig,
    ScannerQRCodeResult
} from "ngx-scanner-qrcode";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ProductionService} from "../../../services/production/production.service";
import {ProductionModel} from "../../../models/production/production.model";
import {Subject, takeUntil} from "rxjs";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {WorkflowModel} from "../../../models/workflow/workflow.model";
import {GeolocationService} from "../../../services/geolocation/geolocation.service";
import {CoordinatesModel} from "../../../models/geolocation/geolocation.model";
import {iResponse} from "../../../models/response/response.model";
import {MatTooltipModule} from "@angular/material/tooltip";
import {DatePipe, registerLocaleData} from "@angular/common";
import * as de from '@angular/common/locales/de';
import {ImageService} from "../../../services/image/image.service";
import {ImageModel} from "../../../models/image/image.model";
import {ImageModalComponent} from "../image-modal/image-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../../services/authentication/authentication.service";
import {RoleModel} from "../../../models/role/role.model";
import {MatSelectModule} from "@angular/material/select";

@Component({
    selector: 'app-production',
    standalone: true,
    imports: [NgxScannerQrcodeModule, ReactiveFormsModule, MatCheckboxModule, MatTooltipModule, RoundedButtonComponent, FormsModule, MatSelectModule],
    templateUrl: './production.component.pug',
    styleUrl: './production.component.sass',
    providers: [DatePipe]
})
export class ProductionComponent implements AfterViewInit, OnDestroy {
    private destroy$ = new Subject<void>();
    scannerOpen: boolean = false
    streamStarted: boolean = false
    products: ProductionModel[] = [];
    stream: MediaStream | null = null
    activeWorkflow: WorkflowModel | null = null
    role: string = '';

    productForm: FormGroup = new FormGroup<any>({
        uuid: new FormControl<string>(''),
    })

    selectedProduct: ProductionModel | null = null;
    barcode: string = '';
    startTime: number = 0;
    endTime: number = 0;

    checkBoxStates: { [key: string]: boolean } = {};

    @ViewChild('action') action!: NgxScannerQrcodeComponent
    public scannerConfig: ScannerQRCodeConfig = {
        constraints: {
            video: {
                width: 300,
            }
        }
    }
    @ViewChild('canvasElement') canvasElement!: ElementRef;
    @ViewChild('videoElement') videoElement!: ElementRef;

    @HostListener('document:keydown', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        if (event.key !== 'Shift' && event.key !== 'Enter') {
            this.startTime = new Date().getTime();
            this.barcode += event.key;
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            this.endTime = new Date().getTime();
            if (this.barcode.startsWith('BETON#') && (this.endTime - this.startTime < 10)) {
                const scannedValue = this.barcode.split('BETON#')[1];
                const orderNumber = scannedValue.split('/')[0];
                const shaftNumber = scannedValue.split('/')[1];
                this.loadProductByOrderAndShaft(orderNumber, shaftNumber);
                this.barcode = '';
            } else {
                this.barcode = '';
            }

        }
    }

    constructor(
        private productionService: ProductionService,
        private infoMessageService: InfoMessageService,
        private geolocationService: GeolocationService,
        private imageService: ImageService,
        private dialog: MatDialog,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        registerLocaleData(de.default);
    }

    ngAfterViewInit() {
        this.loadProductions();
        this.role = this.filterUnusedRoles(this.authenticationService.getRoles())[0];
    }

    ngOnDestroy() {
        if (this.action) {
            this.action.stop();
        }
        this.destroy$.next();
        this.stream?.getVideoTracks().forEach(track => track.stop());
        this.streamStarted = false;
    }

    openScanner() {
        this.scannerOpen = !this.scannerOpen;
        this.startDevice();

    }

    onScannerEvent(event: ScannerQRCodeResult[], action?: any) {
        if (event[0].value) {
            const orderNumber = event[0].value.split('/')[0];
            const shaftNumber = event[0].value.split('/')[1];
            this.scanProductByOrderAndShaft(orderNumber, shaftNumber);
            this.scannerOpen = false;
            this.action.stop();
        }
    }

    closeScanner() {
        this.scannerOpen = false;
        this.action.stop();
    }

    setProduct() {
        const prodUuid = this.productForm.get('uuid')?.value;
        if (prodUuid) {
            this.loadProductionByUuid(prodUuid);
        } else {
            this.selectedProduct = null;
            // TODO set selected Product null
        }
    }

    setWorkflow(event: any, workflow: WorkflowModel) {
        switch (event.checked) {
            case true:
                if (workflow.coordinates) {
                    this.triggerGetGeolocation();
                }
                this.setWorkflowDone(workflow.uuid);
                break;
            case false:
                this.setWorkflowUndone(workflow.uuid);
                break;
        }
    }

    openCameraAndTakePicture(workflow: WorkflowModel): void {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({video: {facingMode: "environment"}})
                .then((stream) => {
                    this.stream = stream;
                    this.streamStarted = true;
                    // Set the video element's stream
                    const video: HTMLVideoElement = this.videoElement.nativeElement;
                    video.srcObject = stream;
                    video.play();
                    this.activeWorkflow = workflow;
                })
                .catch((error) => {
                    console.error("Error accessing camera", error);
                    this.activeWorkflow = null;
                    // Handle errors or inform the user they cannot access the camera
                });
        } else {
            console.error("Camera access is not supported by this browser.");
            this.activeWorkflow = null;
            // Inform the user their browser does not support camera access
        }
    }

    captureImage(): void {
        const video: HTMLVideoElement = this.videoElement.nativeElement;
        const canvas: HTMLCanvasElement = this.canvasElement.nativeElement;
        const context = canvas.getContext('2d');
        if (context) {
            // Set the canvas size to the video size
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            // Draw the video frame to the canvas
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            // Convert the canvas to a data URL
            const imageDataUrl = canvas.toDataURL('image/png');

            // Log the data URL or create an image element to display it
            const base64Data = imageDataUrl.split(',')[1];

            const newImage: ImageModel = {
                uuid: null,
                imageName: this.activeWorkflow!.name,
                imageData: base64Data,
                imageType: 'image/png',
                createdAt: null
            }

            if (this.activeWorkflow!.imageUuid) {
                this.addImage(newImage, true)
            } else {
                this.addImage(newImage);
            }


            // You can also convert the data URL to a Blob if you need to upload the image
            // This is optional and can be done only if image upload is required
            fetch(imageDataUrl)
                .then(res => res.blob())
                .then(blob => {
                    // Blob can be sent to the server using FormData or any other method
                });
        }
    }

    openImageModal(workflow: WorkflowModel): void {
        const dialogRef = this.dialog.open(ImageModalComponent, {
            data: {image: workflow.picture, showRenew: true},
            closeOnNavigation: true,
            disableClose: true,

        })
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result == 'newImage') {
                    this.openCameraAndTakePicture(workflow)
                }
            }
        })
    }

    abortImage(): void {
        this.streamStarted = false;
        this.stream?.getVideoTracks().forEach(track => track.stop());
    }

    showLocation() {
        this.geolocationService.getCoordinates(this.selectedProduct?.coordinatesUuid!)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (coordinates: iResponse<CoordinatesModel>) => {
                    this.router.navigate(['production/work/map'], {
                        state: {
                            latitude: coordinates.data.latitude,
                            longitude: coordinates.data.longitude
                        }
                    });
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    scanProductByOrderAndShaft(orderNumber: string, shaftNumber: string) {
        this.loadProductByOrderAndShaft(orderNumber, shaftNumber);
    }

    triggerGetGeolocation() {
        this.getCoordinates();
    }

    private startDevice() {
        const playDeviceFacingBack = (devices: any[]) => {
            // front camera or back camera check here!
            const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
            this.action.playDevice(device ? device.deviceId : devices[0].deviceId);
        }
        this.action.start(playDeviceFacingBack);
    }

    private loadProductions() {
        this.productionService.getProductions()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.products = response.data;
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private addCoordinates(lat: number, lng: number) {
        const coordinates: CoordinatesModel = {
            latitude: lat,
            longitude: lng,
            uuid: null
        }

        this.geolocationService.addCoordinates(coordinates, this.selectedProduct?.uuid as string)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.loadProductionByUuid(this.selectedProduct?.uuid as string);
                    this.infoMessageService.createMessage('Die Koordinaten wurden gespeichert', 'success');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private loadProductionByUuid(uuid: string) {
        this.checkBoxStates = {};
        this.productionService.getProduction(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.selectedProduct = response.data;
                    this.productForm.get('uuid')?.setValue(response.data.uuid);

                    this.selectedProduct.workflows.forEach(wfl => {
                        this.checkBoxStates[wfl.uuid] = !!wfl.doneAt;
                        const datePipe = new DatePipe('de-DE');
                        const formattedDate = datePipe.transform(wfl.doneAt, 'dd.MM.yyyy \'um\' HH:mm:ss');
                        const infoText = `Erledigt am ${formattedDate}\nvon ${wfl.user}`;
                        wfl.infoText = infoText;
                    })
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private loadProductByOrderAndShaft(orderNumber: string, shaftNumber: string) {
        this.checkBoxStates = {};
        this.productionService.getProductByOrderAndShaft(orderNumber, shaftNumber)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.selectedProduct = response.data;
                    this.productForm.get('uuid')?.setValue(response.data.uuid);
                    this.selectedProduct.workflows.forEach(wfl => {
                        this.checkBoxStates[wfl.uuid] = !!wfl.doneAt;
                        const datePipe = new DatePipe('de-DE');
                        const formattedDate = datePipe.transform(wfl.doneAt, 'dd.MM.yyyy \'um\' HH:mm:ss');
                        const infoText = `Erledigt am ${formattedDate}\nvon ${wfl.user}`;
                        wfl.infoText = infoText;
                    })
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private setWorkflowDone(uuid: string) {
        if (uuid === '0671461c-3900-4a01-b3c6-d4891e50dfa3') {
            this.productionService.setDeliveryNoteWorkflowDone(this.selectedProduct!.orderNumber, this.selectedProduct!.shaftNumber)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response) => {
                        this.productForm.get('uuid')?.patchValue('');
                        this.selectedProduct = null;
                        this.loadProductions();
                    },
                    error: (error) => {
                        this.checkBoxStates[uuid] = false;
                        this.infoMessageService.createMessage(error.error.message, 'error');
                    }
                })
        }
        else if (uuid == 'c07bd585-587c-4fa3-9d02-79e37d9df0ee') {
            this.productionService.setInvoiceWorkflowDone(this.selectedProduct!.orderNumber, this.selectedProduct!.shaftNumber)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response) => {
                        this.productForm.get('uuid')?.patchValue('');
                        this.selectedProduct = null;
                        this.loadProductions();
                    },
                    error: (error) => {
                        this.checkBoxStates[uuid] = false;
                        this.infoMessageService.createMessage(error.error.message, 'error');
                    }
                })
        } else {
            this.productionService.setWorkflowDone(uuid, this.selectedProduct?.uuid as string)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response) => {
                        const foundIndex = this.selectedProduct!.workflows.findIndex(wfl => wfl.uuid === response.data.uuid)

                        if (foundIndex !== undefined) {
                            this.selectedProduct!.workflows[foundIndex] = response.data
                        }
                        this.selectedProduct!.workflows.forEach(wfl => {
                            const datePipe = new DatePipe('de-DE');
                            const formattedDate = datePipe.transform(wfl.doneAt, 'dd.MM.yyyy \'um\' HH:mm:ss');
                            const infoText = `Erledigt am ${formattedDate}\nvon ${wfl.user}`;
                            wfl.infoText = infoText;
                        })
                        this.checkBoxStates[uuid] = true;
                        // this.infoMessageService.createMessage(response, 'success');
                    },
                    error: (error) => {
                        this.checkBoxStates[uuid] = false;
                        this.infoMessageService.createMessage(error.error.message, 'error');
                    }
                })
        }
    }

    private setWorkflowUndone(uuid: string) {
        this.productionService.setWorkflowUndone(uuid, this.selectedProduct?.uuid as string)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    const foundIndex = this.selectedProduct!.workflows.findIndex(wfl => wfl.uuid === response.data.uuid)

                    if (foundIndex !== undefined) {
                        this.selectedProduct!.workflows[foundIndex] = response.data
                    }
                    // this.infoMessageService.createMessage(response, 'success');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private getCoordinates() {
        this.geolocationService.getFilteredPosition(10).then(
            (response) => {
                this.addCoordinates(response.latitude, response.longitude);
            },
            (error) => {
                this.infoMessageService.createMessage(error, 'error');
            }
        )
    }

    private addImage(data: ImageModel, mustDeleteOld: boolean = false) {
        this.imageService.addImage(data, this.activeWorkflow!.uuid, this.selectedProduct!.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.abortImage();
                    if (mustDeleteOld) {
                        this.infoMessageService.createMessage('Das Bild wurde überschrieben', 'success');
                    } else {
                        this.infoMessageService.createMessage('Das Bild wurde gespeichert', 'success');
                    }
                    const uuidToDelete = this.activeWorkflow!.imageUuid;
                    if (mustDeleteOld) {
                        this.deleteImage(uuidToDelete!);
                    }
                    this.loadProductionByUuid(this.selectedProduct!.uuid);
                    this.activeWorkflow = null;
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error.message, 'error');
                }
            })
    }

    private deleteImage(uuid: string) {
        this.imageService.deleteImage(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error.message, 'error');
                }
            })
    }

    private filterUnusedRoles(roles: string[]): string[] {
        return roles.filter(role => role !== 'uma_authorization' && role !== 'offline_access' && role !== 'default-roles-beton-mueller');
    }

}
