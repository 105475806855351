import {Component, Input, OnInit} from "@angular/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgClass} from "@angular/common";

@Component({
    selector: 'app-rounded-button',
    templateUrl: './rounded-button.component.pug',
    standalone: true,
    imports: [MatTooltipModule, NgClass],
})

export class RoundedButtonComponent implements OnInit {
    @Input() fontAwesomeIcon: string = '';
    @Input() tooltip: string = '';
    @Input() additionalClass: string = '';

    constructor() {
    }

    ngOnInit(): void {
    }

    itemToTooltip() {
        return `${this.tooltip}`;
    }
}
