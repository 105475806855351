import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {iResponseList} from "../../models/response/response.model";
import {catchError} from "rxjs/operators";
import {HttpService} from "../http/http.service";
import {RoleModel} from "../../models/role/role.model";

@Injectable({providedIn: 'root'})
export class RoleService extends HttpService{

    getRoles(): Observable<iResponseList<RoleModel>> {
        return this.get('role').pipe(
            map((response: iResponseList<RoleModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }
}
