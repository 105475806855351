import {Component, OnDestroy, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {ProductionService} from "../../../services/production/production.service";
import {ProductionModel} from "../../../models/production/production.model";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {WorkflowModel} from "../../../models/workflow/workflow.model";
import {iResponse, iResponseList} from "../../../models/response/response.model";
import {WorkflowService} from "../../../services/workflow/workflow.service";
import {CategoryModel} from "../../../models/category/category.model";
import {CategoryService} from "../../../services/category/category.service";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {DraftModel} from "../../../models/draft/draft.model";
import {DraftService} from "../../../services/draft/draft.service";
import {Subject, takeUntil} from "rxjs";
import {CanDeactivateType} from "../../../guards/unsaved-changes/unsaved-changes.guard";
import {NonDuplicatedWorkflowsComponent} from "../non-duplicated-workflows/non-duplicated-workflows.component";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";
import {MatSelectModule} from "@angular/material/select";
import {SubcategoryModel} from "../../../models/subcategory/subcategory.model";
export interface NestedCategories {
    category: CategoryModel
    subCategories: SubcategoryModel[]
}
@Component({
    selector: 'app-planning-administration',
    standalone: true,
    imports: [RouterLink, ReactiveFormsModule, CdkDropList, CdkDrag, RoundedButtonComponent, MatSelectModule],
    templateUrl: './planning-administration.component.pug',
    styleUrl: './planning-administration.component.sass'
})
export class PlanningAdministrationComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    productions: ProductionModel[] = [];
    filteredProductions: ProductionModel[] = [];
    workflows: WorkflowModel[] = [];
    workflowsCopy: WorkflowModel[] = [];
    selectedWorkflowsCopy: WorkflowModel[] = [];
    categories: CategoryModel[] = [];
    nestedCategories: NestedCategories[] = [];
    drafts: DraftModel[] = [];
    draftsCopy: DraftModel[] = [];
    filterForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
    })
    filterTimeout: any;

    productionForm: FormGroup = new FormGroup<any>({
        uuid: new FormControl<string>(''),
        orderNumber: new FormControl<string>(''),
        shaftNumber: new FormControl<string>(''),
        coordinatesUuid: new FormControl<string>(''),
        workflowUuids: new FormControl<string>(''),
    })

    categoryForm: FormGroup = new FormGroup({
        uuid: new FormControl<string>(''),
    })

    subcategoryForm: FormGroup = new FormGroup({
        uuid: new FormControl<string>(''),
    })

    selectedProduction: ProductionModel = {
        uuid: '',
        orderNumber: '',
        shaftNumber: '',
        coordinatesUuid: '',
        workflowUuids: [],
        workflows: [],
        buildingSite: '',
        company: ''
    };
    addWorkflowBool: boolean = false;
    addDraftBool: boolean = false;

    constructor(
        private infoMessageService: InfoMessageService,
        private productionService: ProductionService,
        private categoryService: CategoryService,
        private workflowService: WorkflowService,
        private draftService: DraftService,
        private dialog: MatDialog
    ) {
    }


    ngOnInit() {
        this.loadProductionList();
        this.loadCategoryList();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    activateAddWorkflow() {
        this.addWorkflowBool = !this.addWorkflowBool;
    }

    activateAddDraft() {
        this.addDraftBool = !this.addDraftBool
        if (this.addDraftBool) {
            this.loadDrafts();
        }
    }

    drop(event: CdkDragDrop<WorkflowModel[]>, workflows: WorkflowModel[]) {

        moveItemInArray(workflows, event.previousIndex, event.currentIndex);
        workflows.forEach((wfl, index) => {

            wfl.sortIndex = index;
        })
    }

    filterList(listToFilter: ProductionModel[]) {
        const filterValue = this.filterForm.get('name')?.value.toLowerCase();

        if (filterValue.length < 3) {
            this.filteredProductions = [...this.productions];
            return;
        }

        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            this.filteredProductions = listToFilter.filter(production =>
                production.orderNumber.toLowerCase().includes(filterValue) ||
                (production.shaftNumber && production.shaftNumber.toLowerCase().includes(filterValue))
            );
        }, 500);
    }

    resetForm() {
        this.productionForm.reset();
        this.productionForm.get('uuid')?.patchValue('');
        this.productionForm.get('orderNumber')?.patchValue('');
        this.productionForm.get('shaftNumber')?.patchValue('');
        this.productionForm.get('coordinatesUuid')?.patchValue('');
        this.productionForm.get('workflowUuids')?.patchValue([]);
        this.categoryForm.get('uuid')?.patchValue('');
        this.selectedProduction = {} as ProductionModel;
        this.workflows = [];
    }

    selectProduction(production: ProductionModel) {
        this.addDraftBool = false;
        this.addWorkflowBool = false;
        this.categoryForm.get('uuid')?.patchValue('');
        this.loadProduction(production);
    }

    loadWorkflows(workflowArray: WorkflowModel[]) {
        const uuid = this.subcategoryForm.get('uuid')?.value;
        if (!uuid) {
            return;
        }
        this.workflowService.getWorkflowListBySubCategory(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<WorkflowModel>) => {
                    const selectedWorkflows = response.data.filter(workflow => this.isSelected(workflow, workflowArray));
                    this.workflows = response.data.filter(workflow => !selectedWorkflows.includes(workflow));
                    this.workflowsCopy = [...response.data];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    selectWorkflow(workflow: WorkflowModel, workflows: WorkflowModel[]) {
        const found = workflows.find(w => w.uuid === workflow.uuid);
        if (!found) {
            this.handleAddWorkflowToList(workflow, workflows);
        } else {
            if (workflow.doneAt) {
                this.openConfirmDialog('Achtung', 'Dieser Arbeitsschritt wurde bereits bearbeitet. Wollen Sie den Arbeitsschritt wirklich entfernen?', this.selectedProduction, 'workflow-solved', workflow, workflows);
            }
            else if (workflow.isStandard) {
                this.openConfirmDialog('Achtung', 'Hierbei handelt es sich um einen Standard-Arbeitsschritt. Möchten Sie diesen wirklich löschen?', this.selectedProduction, 'workflow-standard', workflow, workflows);
            } else {
                this.handleDeleteWorkflowFromList(workflow, workflows);
            }
        }
    }

    openDelete(production: ProductionModel) {
        this.openConfirmDialog('Löschen', 'Soll dieser Produktionsablauf wirklich gelöscht werden?', production, 'delete');
    }


    private loadDrafts() {
        this.draftService.getDrafts()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<DraftModel>) => {
                    this.drafts = response.data;
                    this.draftsCopy = [...response.data];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private openConfirmDialog(title: string, message: string, production: ProductionModel, type: string, workflow?: WorkflowModel, workflows?: WorkflowModel[]) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: title,
                message: message
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                switch (type) {
                    case 'delete':
                        this.delete(production);
                        break;
                    case 'restore':
                        break;
                    case 'workflow-solved':
                        if (workflow && workflows) {
                            this.handleDeleteWorkflowFromList(workflow, workflows);
                        }
                        break;
                    case 'workflow-standard':
                        if (workflow && workflows) {
                            this.handleDeleteWorkflowFromList(workflow, workflows);
                        }
                        break;
                    default:
                        break;
                }
            }
        });
    }

    handleDeleteWorkflowFromList(workflow: WorkflowModel, workflows: WorkflowModel[]) {
        const index = workflows.findIndex(w => w.uuid === workflow.uuid);
        workflows.splice(index, 1);
        const foundIndexInCopy = this.workflowsCopy.findIndex(w => w.uuid === workflow.uuid);
        if (foundIndexInCopy !== -1) {
            this.workflows.splice(foundIndexInCopy, 0, workflow);
        }
        this.setIndex(workflows);
    }

    handleAddWorkflowToList(workflow: WorkflowModel, workflows: WorkflowModel[]) {
        workflows.push(workflow);
        const index = this.workflows.findIndex(w => w.uuid === workflow.uuid);
        this.workflows.splice(index, 1);
        this.setIndex(workflows);
    }

    setIndex(workflows: WorkflowModel[]) {
        workflows.forEach((wfl, index) => {
            wfl.sortIndex = index;
        })
    }

    update() {
        this.selectedProduction.workflows.forEach(wfl => {
            if (!wfl.imageUuid) {
                wfl.picture = null;
            }
        })
        this.productionService.updateProduction(this.selectedProduction)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponse<ProductionModel>) => {
                    let index = this.productions.findIndex(p => p.uuid === this.selectedProduction.uuid);
                    this.productions.splice(index, 1, response.data);
                    this.productionForm.reset();
                    this.infoMessageService.createMessage('Die Produktion wurde erfolgreich aktualisiert', 'success');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    addDraftToProduction(draft: DraftModel) {
        this.draftService.getDraft(draft.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponse<DraftModel>) => {
                    const uuidsOfResponse = new Set(response.data.workflows.map(wfl => wfl.uuid));
                    const hasDuplicates = this.selectedProduction.workflows.some(wfl => uuidsOfResponse.has(wfl.uuid));
                    if (hasDuplicates) {
                        let nonDuplicatedWorkflows: WorkflowModel[] = [];
                        response.data.workflows.forEach(wfl => {
                            const isDuplicate = this.selectedProduction.workflows.some(w => w.uuid === wfl.uuid);
                            if (!isDuplicate) {
                                nonDuplicatedWorkflows.push(wfl);
                            }
                        })
                        if (nonDuplicatedWorkflows.length === 0) {
                            this.infoMessageService.createMessage("Alle Arbeitschritte dieser Vorlage sind bereits im Produktionsablauf vorhanden.", 'error');
                            return;
                        } else {
                            this.openNonDuplicatedWorkflowsDialog(nonDuplicatedWorkflows);
                            return
                        }
                    }
                    response.data.workflows.forEach(wfl => {
                        const found = this.selectedProduction.workflows.find(w => w.uuid === wfl.uuid);
                        if (!found) {
                            this.selectWorkflow(wfl, this.selectedProduction.workflows)
                        }
                    })
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error);
                }
            })
    }

    private delete(production: ProductionModel) {
        this.productionService.deleteProduction(production.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponse<ProductionModel>) => {
                    let index = this.productions.findIndex(p => p.uuid === production.uuid);
                    this.productions.splice(index, 1);
                    this.filteredProductions.splice(index, 1);
                    this.infoMessageService.createMessage('Die Produktion wurde erfolgreich gelöscht', 'success');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private isSelected(workflow: WorkflowModel, workflowArray: WorkflowModel[]): boolean {
        return workflowArray.some(selectedWorkflow => selectedWorkflow.uuid === workflow.uuid);
    }

    private loadProduction(production: ProductionModel) {
        this.selectedWorkflowsCopy = [];
        this.productionService.getProduction(production.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponse<ProductionModel>) => {
                    this.productionForm.patchValue(response.data);
                    this.selectedProduction = {...response.data};
                    this.selectedWorkflowsCopy = [...response.data.workflows];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }


    private loadProductionList() {
        this.productionService.getProductions()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<ProductionModel>) => {
                    this.productions = response.data;
                    this.filteredProductions = [...this.productions];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private loadCategoryList() {
        this.categoryService.getCategories()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<CategoryModel>) => {
                    this.categories = response.data;

                    this.categories.forEach(category => {
                        this.loadSubcategoriesByCategoryUuid(category)
                    })
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    loadSubcategoriesByCategoryUuid(category: CategoryModel) {
        const uuid = category.uuid;
        if (!uuid) {

            return;
        }

        this.categoryService.getSubCategories(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<SubcategoryModel>) => {
                    this.nestedCategories.push({
                        category: category,
                        subCategories: response.data
                    })
                },
                error: error => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    private openNonDuplicatedWorkflowsDialog(workflows: WorkflowModel[]) {
        const dialogRef = this.dialog.open(NonDuplicatedWorkflowsComponent, {
            data: {
                workflows
            }
        })
        dialogRef.afterClosed().subscribe((result: WorkflowModel[]) => {
            if (result) {
                result.forEach(wfl => {
                    this.selectWorkflow(wfl, this.selectedProduction.workflows)
                })
            }
        })
    }


    // CAN DEACTIVATE
    canDeactivate(): CanDeactivateType {
        if (!this.selectedWorkflowsCopy || !this.selectedProduction.workflows) {
            return true;
        }
        const areWorkflowsEqual = this.areWorkflowsEqual(this.selectedWorkflowsCopy!, this.selectedProduction.workflows!);
        if (this.productionForm.dirty || !areWorkflowsEqual) {
            const deactivateSubject = new Subject<boolean>();
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                data: {
                    title: 'Ungespeicherte Daten',
                    message: 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Nicht gespeicherte Daten gehen verloren.'
                }
            })

            dialogRef.afterClosed().subscribe(result => {
                deactivateSubject.next(result);
            });
            return deactivateSubject;
        } else {
            return true;
        }
    }
    private areWorkflowsEqual(workflowsA: WorkflowModel[], workflowsB: WorkflowModel[]): boolean {
        const uuidsA = workflowsA.map(w => w.uuid).sort();
        const uuidsB = workflowsB.map(w => w.uuid).sort();
        return JSON.stringify(uuidsA) === JSON.stringify(uuidsB);
    }

}
