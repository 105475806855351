import {Injectable} from "@angular/core";
import {HttpService} from "../http/http.service";
import {iResponse, iResponseList} from "../../models/response/response.model";
import {ProductionModel} from "../../models/production/production.model";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {WorkflowModel} from "../../models/workflow/workflow.model";

@Injectable({providedIn: 'root'})
export class ProductionService extends HttpService{

    getProductions(): Observable<iResponseList<ProductionModel>> {
        return this.get('production').pipe(
            map((response: iResponseList<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    getProduction(uuid: string): Observable<iResponse<ProductionModel>> {
        return this.get(`production/${uuid}`).pipe(
            map((response: iResponse<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    getProductByOrderAndShaft(orderNumber: string, shaftNumber: string): Observable<iResponse<ProductionModel>> {
        return this.get(`production/scan/${orderNumber}/${shaftNumber}`).pipe(
            map((response: iResponse<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    getProductionInProgress(): Observable<iResponseList<ProductionModel>> {
        return this.get('production/in-progress').pipe(
            map((response: iResponseList<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getSolvedProducts(): Observable<iResponseList<ProductionModel>> {
        return this.get('production/solved').pipe(
            map((response: iResponseList<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getDeletedProductions(): Observable<iResponseList<ProductionModel>> {
        return this.get('production/deleted').pipe(
            map((response: iResponseList<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    getArchivedProductions(): Observable<iResponseList<ProductionModel>> {
        return this.get('production/archived').pipe(
            map((response: iResponseList<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    addProduction(data: ProductionModel): Observable<iResponse<ProductionModel>> {
        return this.post('production', data).pipe(
            map((response: iResponse<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    updateProduction(data: ProductionModel): Observable<iResponse<ProductionModel>> {
        return this.put('production', data).pipe(
            map((response: iResponse<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    deleteProduction(uuid: string): Observable<iResponse<ProductionModel>> {
        return this.delete(`production/${uuid}`).pipe(
            map((response: iResponse<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    setWorkflowDone(workflowUuid: string, productionUuid: string): Observable<iResponse<WorkflowModel>> {
        return this.put(`production/workflow/${workflowUuid}/${productionUuid}/done`, null).pipe(
            map((response: iResponse<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    setWorkflowUndone(workflowUuid: string, productionUuid: string): Observable<iResponse<WorkflowModel>> {
        return this.put(`production/workflow/${workflowUuid}/${productionUuid}/undone`, null).pipe(
            map((response: iResponse<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    archiveProduction(uuid: string): Observable<iResponse<ProductionModel>> {
        return this.put(`production/archive/id/${uuid}`, null).pipe(
            map((response: iResponse<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    archiveProductByOrderAndShaft(orderNumber: string, shaftNumber: string): Observable<iResponse<ProductionModel>> {
        return this.put(`production/archive/order-shaft/${orderNumber}/${shaftNumber}`, null).pipe(
            map((response: iResponse<ProductionModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    setDeliveryNoteWorkflowDone(orderNumber: string, shaftNumber: string): Observable<any> {
        return this.put(`production/delivery-note/${orderNumber}/${shaftNumber}`, null).pipe(
            map((response: any) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    setInvoiceWorkflowDone(orderNumber: string, shaftNumber: string): Observable<any> {
        return this.put(`production/invoice/${orderNumber}/${shaftNumber}`, null).pipe(
            map((response: any) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

}
