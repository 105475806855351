import {Component, OnDestroy, OnInit} from '@angular/core';
import {CategoryService} from "../../../services/category/category.service";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {MatDialog} from "@angular/material/dialog";
import {Router, RouterLink} from "@angular/router";
import {CategoryModel} from "../../../models/category/category.model";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {Subject, takeUntil} from "rxjs";
import {CanDeactivateType} from "../../../guards/unsaved-changes/unsaved-changes.guard";
import {AddContentModalComponent} from "../add-content-modal/add-content-modal.component";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";
import {SubcategoryModel} from "../../../models/subcategory/subcategory.model";

@Component({
    selector: 'app-category',
    standalone: true,
    imports: [ReactiveFormsModule, RouterLink, RoundedButtonComponent,],
    templateUrl: './category.component.pug',
    styleUrl: './category.component.sass'
})
export class CategoryComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    // Lists
    categoryList: CategoryModel[] = [];
    filteredCategoryList: CategoryModel[] = [];
    subcategoryList: SubcategoryModel[] = [];
    // Forms
    filterForm: FormGroup = new FormGroup<any>({
        text: new FormControl<string>(''),
    })

    categoryForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
        uuid: new FormControl<string>(''),
    })

    subcategoryForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
        categoryUuid: new FormControl<string>(''),
        uuid: new FormControl<string>(''),
    })
    // Timeout
    filterTimeout: any;
    // Control Booleans
    addCategoryBool: boolean = false;

    constructor(
        private categoryService: CategoryService,
        private infoMessageService: InfoMessageService,
        private dialog: MatDialog,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.triggerGetCategoryList()
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    filterList(listToFilter: CategoryModel[]) {
        const filterValue = this.filterForm.get('text')?.value.toLowerCase();
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            this.filteredCategoryList = listToFilter.filter(category => category.name.toLowerCase().includes(filterValue));
        }, 500)
    }

    triggerGetCategoryList() {
        this.categoryService.getCategories()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.categoryList = [...response.data];
                    this.filteredCategoryList = [...response.data];
                }
            })
    }

    openDeleteCategory(category: CategoryModel) {
        this.openConfirmDialog('Löschen', 'Wollen Sie die Kategorie "' + category.name + '" wirklich löschen?', category, 'delete');
    }

    openDeleteSubcategory(subcategory: SubcategoryModel) {
        this.openConfirmDialog('Löschen', 'Wollen Sie die Unterkategorie "' + subcategory.name + '" wirklich löschen?', subcategory, 'deleteSub');
    }

    activateEditCategory(category: CategoryModel) {
        if (this.categoryForm.get('uuid')?.value !== category.uuid) {
            this.categoryForm.get('name')?.setValue(category.name);
            this.categoryForm.get('uuid')?.setValue(category.uuid);
            this.getSubcategories(category.uuid);
        } else {
            this.subcategoryList = [];
        }
    }

    activateEditSubCategory(subcategory: SubcategoryModel) {
        if (this.subcategoryForm.get('uuid')?.value !== subcategory.uuid) {
            this.subcategoryForm.get('name')?.setValue(subcategory.name);
            this.subcategoryForm.get('categoryUuid')?.setValue(subcategory.categoryUuid);
            this.subcategoryForm.get('uuid')?.setValue(subcategory.uuid);
        }
    }

    openCategory(subcategory: SubcategoryModel) {
        this.router.navigate(['/administration/category/workflow/' + subcategory.uuid]);
    }

    openAddContentModal() {
        const dialogRef = this.dialog.open(AddContentModalComponent, {
            data: {
                title: 'Kategorie hinzufügen',
                message: 'Bitte geben Sie einen Namen ein',
                placeholder: 'Kategoriename',
                type: 'category',
                uuid: ''
            }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.type === 'selected') {

                } else if (result.type === 'name') {
                    let category: CategoryModel = {name: result.selectedOption, uuid: '', deletedAt: null};
                    this.addNewCategory(category);
                }
            }
        })
    }

    openAddSubContentModal() {
        const dialogRef = this.dialog.open(AddContentModalComponent, {
            data: {
                title: 'Unterkategorie hinzufügen',
                message: 'Bitte geben Sie einen Namen ein',
                placeholder: 'Unterkategoriename',
                type: 'subcategory',
                uuid: this.categoryForm.get('uuid')?.value
            }
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.type === 'selected') {

                } else if (result.type === 'name') {
                    let subcategory: SubcategoryModel = {name: result.selectedOption, uuid: '', categoryUuid: this.categoryForm.get('uuid')?.value, deletedAt: null};
                    this.addNewSubcategory(subcategory);
                }
            }
        })
    }

    updateCategory() {
        this.categoryService.updateCategory(this.categoryForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    let foundIndex = this.categoryList.findIndex(category => category.uuid === this.categoryForm.get('uuid')?.value);
                    this.categoryList[foundIndex] = response.data;
                    this.filteredCategoryList = [...this.categoryList];
                    this.infoMessageService.createMessage('Die Kategorie ' + this.categoryForm.get('name')?.value + ' wurde aktualisiert');
                    this.resetCategoryForm();
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error);
                }
            })
    }

    updateSubcategory() {
        this.categoryService.updateSubcategory(this.subcategoryForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    let foundIndex = this.subcategoryList.findIndex(subcategory => subcategory.uuid === this.subcategoryForm.get('uuid')?.value);
                    this.subcategoryList[foundIndex] = response.data;
                    this.infoMessageService.createMessage('Die Unterkategorie ' + this.subcategoryForm.get('name')?.value + ' wurde aktualisiert');
                    this.resetSubcategoryForm();
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error);
                }
            })
    }

    addNewCategory(result: CategoryModel) {
        this.categoryService.addCategory(result)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.categoryList.push(response.data);
                    this.filteredCategoryList = [...this.categoryList];
                    this.resetCategoryForm();
                    this.scrollToBottom();
                    this.infoMessageService.createMessage('Die Kategorie ' + this.categoryForm.get('name')?.value + ' wurde angelegt');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error);
                }
            })
    }

    addNewSubcategory(subcategory: SubcategoryModel) {
        this.categoryService.addSubcategoryToCategory(this.categoryForm.get('uuid')?.value, subcategory)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.subcategoryList.push(response.data);
                    this.resetSubcategoryForm();
                    this.infoMessageService.createMessage('Die Unterkategorie ' + this.subcategoryForm.get('name')?.value + ' wurde angelegt');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error);
                }
            })
    }

    resetCategoryForm() {
        this.categoryForm.reset();
        this.categoryForm.get('name')?.patchValue('');
        this.categoryForm.get('uuid')?.patchValue('');
    }

    resetSubcategoryForm() {
        this.subcategoryForm.reset();
        this.subcategoryForm.get('name')?.patchValue('');
        this.subcategoryForm.get('categoryUuid')?.patchValue('');
        this.subcategoryForm.get('uuid')?.patchValue('');
    }

    private openConfirmDialog(title: string, message: string, category: any, type: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: title,
                message: message
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                switch (type) {
                    case 'delete':
                        this.triggerDeleteCategory(category)
                        break;
                    case 'deleteSub':
                        this.deleteSubCategory(category)
                        break;
                    default:
                        break;
                }
            }
        });


    }

    private triggerDeleteCategory(category: CategoryModel) {
        this.categoryService.deleteCategory(category.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.infoMessageService.createMessage('Die Kategorie ' + category.name + ' wurde gelöscht');
                    let foundIndex = this.categoryList.findIndex(c => c.uuid === category.uuid);
                    this.categoryList.splice(foundIndex, 1);
                    this.filteredCategoryList = [...this.categoryList];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private deleteSubCategory(category: SubcategoryModel) {
        this.categoryService.deleteSubcategory(category.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.infoMessageService.createMessage('Die Unterkategorie ' + category.name + ' wurde gelöscht');
                    let foundIndex = this.subcategoryList.findIndex(c => c.uuid === category.uuid);
                    this.subcategoryList.splice(foundIndex, 1);
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }


    private scrollToBottom() {
        setTimeout(() => {
            const element = document.querySelector('section');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        }, 500)
    }

    private getSubcategories(uuid: string) {
        this.categoryService.getSubCategories(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.subcategoryList = [...response.data];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }


    canDeactivate(): CanDeactivateType {
        if (this.categoryForm.dirty) {
            const deactivateSubject = new Subject<boolean>();
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                data: {
                    title: 'Ungespeicherte Daten',
                    message: 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Nicht gespeicherte Daten gehen verloren.'
                }
            })

            dialogRef.afterClosed().subscribe(result => {
                deactivateSubject.next(result);
            });
            return deactivateSubject;
        } else {
            return true;
        }
    }
}
