import {Component, OnInit} from '@angular/core';
import {DashboardTileComponent} from "../../../shared-components/tiles/dashboard-tile/dashboard-tile.component";

@Component({
  selector: 'app-planning-dashboard',
  standalone: true,
  imports: [DashboardTileComponent],
  templateUrl: './planning-dashboard.component.pug',
  styleUrl: './planning-dashboard.component.sass'
})
export class PlanningDashboardComponent {
    constructor() {
    }
}
