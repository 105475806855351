import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProductionService} from "../../../services/production/production.service";
import {Subject, takeUntil} from "rxjs";
import {ProductionModel} from "../../../models/production/production.model";
import {iResponse} from "../../../models/response/response.model";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {DatePipe, registerLocaleData} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {GeolocationService} from "../../../services/geolocation/geolocation.service";
import * as de from '@angular/common/locales/de';
import {CoordinatesModel} from "../../../models/geolocation/geolocation.model";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTooltipModule} from "@angular/material/tooltip";
import {WorkflowModel} from "../../../models/workflow/workflow.model";
import {ImageModalComponent} from "../image-modal/image-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-production-solved',
  standalone: true,
  imports: [MatCheckboxModule, MatTooltipModule, ReactiveFormsModule, RoundedButtonComponent],
  templateUrl: './production-solved.component.pug',
  styleUrl: './production-solved.component.sass'
})
export class ProductionSolvedComponent implements OnInit, OnDestroy{
    private destroy$ = new Subject<void>();
    solvedProductions: ProductionModel[] = [];
    filteredProductionsList: ProductionModel[] = [];
    selectedProductionForm: FormGroup = new FormGroup<any>({
        uuid: new FormControl<string>(''),
    })
    filterForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
    })

    filterTimeout: any;
    constructor(
        private productionService: ProductionService,
        private infoMessageService: InfoMessageService,
        private geolocationService: GeolocationService,
        private dialog: MatDialog,
        private router: Router
    ) {
        registerLocaleData(de.default);
    }


    ngOnInit(): void {
        this.getSolvedProductions();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    filterList() {
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            const filterValue = this.filterForm.get('name')?.value.toLowerCase();

            if (filterValue.length < 3) {
                this.filteredProductionsList = [...this.solvedProductions];
                return;
            }
            const lowerCaseFilterValue = filterValue;
            this.filteredProductionsList = this.solvedProductions.filter((production: ProductionModel) => {
                return production.orderNumber.toLowerCase().includes(lowerCaseFilterValue) ||
                    (production.shaftNumber && production.shaftNumber.toLowerCase().includes(lowerCaseFilterValue)) ||
                    (production.company && production.company.toLowerCase().includes(lowerCaseFilterValue)) ||
                    (production.buildingSite && production.buildingSite.toLowerCase().includes(lowerCaseFilterValue));
            });
        }, 500);
    }

    openProduction(production: ProductionModel) {
        if (this.selectedProductionForm.get('uuid')?.value === production.uuid) {
            this.selectedProductionForm.get('uuid')?.setValue('');
        } else {
            this.selectedProductionForm.get('uuid')?.setValue(production.uuid);
            production.workflows.forEach(wfl => {
                const datePipe = new DatePipe('de-DE');
                const formattedDate = datePipe.transform(wfl.doneAt, 'dd.MM.yyyy \'um\' HH:mm:ss');
                const infoText = `Erledigt am ${formattedDate}\nvon ${wfl.user}`;
                wfl.infoText = infoText;
            })
        }
    }

    showLocation(production: ProductionModel) {
        this.geolocationService.getCoordinates(production.coordinatesUuid!)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (coordinates: iResponse<CoordinatesModel>) => {
                    this.router.navigate(['production/work/map-hedgehog'],{state: {latitude: coordinates.data.latitude, longitude: coordinates.data.longitude}});
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error.message, 'error');
                }
            })
    }

    getSolvedProductions() {
        this.productionService.getSolvedProducts()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (productions: iResponse<ProductionModel[]>) => {
                    this.solvedProductions = productions.data;
                    this.filteredProductionsList = [...this.solvedProductions];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error.message, 'error');
                }
            })
    }
    openImageModal(workflow: WorkflowModel): void {
        const dialogRef = this.dialog.open(ImageModalComponent, {
            data: {image: workflow.picture, showRenew: false},
            closeOnNavigation: true,
            disableClose: true,

        })
        dialogRef.afterClosed().subscribe((result: any) => {

        })
    }
}
