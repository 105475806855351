import { Injectable } from '@angular/core';
import { Storage } from './model/storage';

@Injectable({providedIn: 'root'})
export class SessionDb {
	private storages: Map<string, Storage<any>> = new Map<string, Storage<any>>();

	createStorage<T>(t: { new(): T; }, name?: string): Storage<T> {
		let className: string;
		if (name) {
			className = name;
		} else {
			className = t.name;
		}
		if (this.storages.has(className)) {
			throw new Error('Storage already created');
		}

		const storage = new Storage<T>(t, className);
		this.storages.set(className, storage);
		return storage;
	}

	getStorage<T>(t: { new(): T; }, name?: string): Storage<T>|undefined {
		let className: string;
		if (name) {
			className = name;
		} else {
			className = t.name;
		}

		if (this.storages.has(className)) {
			return this.storages.get(className);
		}
		throw new Error('Storage for "' + className + '" does not exist (not created)');
	}

}
