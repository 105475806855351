import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../../services/user/user.service";
import {Subject, takeUntil} from "rxjs";
import {UserModel} from "../../../models/user/user.model";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError} from "@angular/material/form-field";
import {RoleModel} from "../../../models/role/role.model";

@Component({
    selector: 'app-add-user-modal',
    standalone: true,
    imports: [ReactiveFormsModule, MatError, FormsModule],
    templateUrl: './add-user-modal.component.pug',
    styleUrl: './add-user-modal.component.sass'
})
export class AddUserModalComponent implements OnDestroy, OnInit {
    private destroy$ = new Subject<void>();
    roles: RoleModel[] = [];
    passwordMissMatch: boolean = false;

    userForm: FormGroup = new FormGroup<any>({
        firstName: new FormControl<string>('', Validators.required),
        lastName: new FormControl<string>('', Validators.required),
        username: new FormControl<string>('', Validators.required),
        password: new FormControl<string>('', Validators.required),
        confirmPassword: new FormControl<string>('', Validators.required),
        roleUuid: new FormControl<string>('', Validators.required),
    })

    constructor(public dialogRef: MatDialogRef<AddUserModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private userService: UserService,
                ) {
        if (data.roles) {
            this.roles = [...data.roles];
            console.log(this.roles);
        }
    }

    ngOnInit() {
        this.userForm.get('firstName')?.valueChanges.subscribe(() => {
            this.setFirstTwoLettersAsUserName();
        });

        this.userForm.get('lastName')?.valueChanges.subscribe(() => {
            this.setFirstTwoLettersAsUserName();
        });

        this.userForm.get('confirmPassword')?.valueChanges.subscribe(() => {
            this.checkIfPasswordConfirms();
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }


    onClose() {
        this.dialogRef.close();
    }

    setFirstTwoLettersAsUserName() {
        // Check if both firstName and lastName have values
        if (this.userForm.get('firstName')?.value && this.userForm.get('lastName')?.value) {
            // Extract the first two letters from firstName and lastName
            const firstNameInitials = this.userForm.get('firstName')?.value.substring(0, 2).toLowerCase();
            const lastNameInitials = this.userForm.get('lastName')?.value.substring(0, 2).toLowerCase();

            // Combine the initials and set them as the username
            this.userForm.get('username')?.patchValue(firstNameInitials + lastNameInitials);
        }
    }

    checkIfPasswordConfirms() {
        this.passwordMissMatch = this.userForm.get('password')?.value !== this.userForm.get('confirmPassword')?.value;
    }



    onSubmit() {
        if (!this.userForm.valid) {
            this.userForm.markAllAsTouched();
            return
        }
        const newUser: UserModel = {
            firstName: this.userForm.get('firstName')?.value,
            lastName: this.userForm.get('lastName')?.value,
            username: this.userForm.get('username')?.value,
            password: this.userForm.get('password')?.value,
            realmRoles: [this.userForm.get('roleUuid')?.value],
            enabled: true,
            id: null,
            createdTimestamp: null,
            email: null,
            groups: null
        }
        this.saveUserDate(newUser)
    }

    private saveUserDate(user: UserModel) {
        this.userService.addUser(user)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.dialogRef.close(true);
                },
                error: () => {
                    // this.dialogRef.close();
                }
            })
    }
}
