import {Injectable} from "@angular/core";
import {HttpService} from "../http/http.service";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {ImageModel} from "../../models/image/image.model";
import {iResponse} from "../../models/response/response.model";

@Injectable({providedIn: 'root'})
export class ImageService extends HttpService {

    addImage(data: ImageModel, workflowUuid: string, productionUuid: string): Observable<iResponse<ImageModel>> {
        return this.post(`image/${workflowUuid}/${productionUuid}`, data).pipe(
            map((response: iResponse<ImageModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    deleteImage(uuid: string): Observable<any> {
        return this.delete(`image/${uuid}`).pipe(
            map((response: any) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getImage(uuid: string): Observable<any> {
        return this.get(`image/${uuid}`).pipe(
            map((response: any) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }
}
