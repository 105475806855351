import {Component, inject} from '@angular/core';
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarAction,
    MatSnackBarActions,
    MatSnackBarLabel,
    MatSnackBarRef
} from "@angular/material/snack-bar";
import {InfoMessageModel} from "../../../models/infomessage/infomessage.model";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-infomessage',
  standalone: true,
  imports: [MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction, NgClass],
  templateUrl: './infomessage.component.pug',
  styleUrl: './infomessage.component.sass'
})
export class InfoMessageComponent {
    snackBarRef = inject(MatSnackBarRef)
    infoMessageModel: InfoMessageModel = inject(MAT_SNACK_BAR_DATA) as InfoMessageModel
}
