import {Injectable} from "@angular/core";
import {HttpService} from "../http/http.service";
import {CoordinatesModel} from "../../models/geolocation/geolocation.model";
import {iResponse} from "../../models/response/response.model";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";

interface Coordinates {
    latitude: number;
    longitude: number;
}

interface Area {
    topLeft: Coordinates;
    bottomRight: Coordinates;
}

@Injectable({providedIn: 'root'})
export class GeolocationService extends HttpService {

    private topLeftGeo = {latitude: 48.645651, longitude: 8.058910}
    private bottomRightGeo = {latitude: 48.643773, longitude: 8.061912}

    getFilteredPosition(samplesCount: number, maxAttempts: number = 10): Promise<Coordinates> {
        let positions: GeolocationPosition[] = [];
        let attempts = 0; // Counter for the number of attempts

        return new Promise((resolve, reject) => {
            if ('geolocation' in navigator) {
                const getPosition = () => {
                    if (attempts >= maxAttempts) {
                        // Too many attempts, resolve or reject the promise based on positions collected
                        positions.length ? resolve(this.calculateMedianPosition(positions)) : reject('Maximum attempts reached without sufficient data');
                        return;
                    }
                    navigator.geolocation.getCurrentPosition(position => {
                        attempts++; // Increment attempts on each call
                        if (this.isPositionWithinArea(position, this.topLeftGeo, this.bottomRightGeo)) {
                            positions.push(position);
                        }
                        if (positions.length < samplesCount) {
                            getPosition(); // Recursively get more positions
                        } else {
                            resolve(this.calculateMedianPosition(positions));
                        }
                    }, (error) => {
                        attempts++; // Increment attempts on error
                        if (attempts < maxAttempts) {
                            getPosition(); // Try again if under max attempts
                        } else {
                            reject('Geolocation error: ' + error.message);
                        }
                    }, {maximumAge:60000, timeout:5000, enableHighAccuracy: true});
                };

                getPosition();
            } else {
                reject('Geolocation is not available');
            }
        });
    }

    private isPositionWithinArea(position: GeolocationPosition, topLeft: Coordinates, bottomRight: Coordinates): boolean {
        const { latitude, longitude } = position.coords;
        return latitude <= topLeft.latitude && latitude >= bottomRight.latitude &&
            longitude >= topLeft.longitude && longitude <= bottomRight.longitude;
    }

    private calculateMedianPosition(positions: GeolocationPosition[]): Coordinates {
        let latitudes = positions.map(pos => pos.coords.latitude).sort((a, b) => a - b);
        let longitudes = positions.map(pos => pos.coords.longitude).sort((a, b) => a - b);
        let medianLat = latitudes[Math.floor(latitudes.length / 2)];
        let medianLng = longitudes[Math.floor(longitudes.length / 2)];

        return { latitude: medianLat, longitude: medianLng };
    }

    getCoordinates(uuid: string): Observable<iResponse<CoordinatesModel>> {
        return this.get(`coordinates/${uuid}`).pipe(
            map((response: iResponse<CoordinatesModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    addCoordinates(data: CoordinatesModel, uuid: string): Observable<iResponse<CoordinatesModel>> {
        return this.post(`coordinates/${uuid}`, data).pipe(
            map((response: iResponse<CoordinatesModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    updateCoordinates(data: CoordinatesModel, uuid: string): Observable<iResponse<CoordinatesModel>> {
        return this.put(`coordinates/${uuid}`, data).pipe(
            map((response: iResponse<CoordinatesModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    deleteCoordinates(uuid: string): Observable<iResponse<CoordinatesModel>> {
        return this.delete(`coordinates/${uuid}`).pipe(
            map((response: iResponse<CoordinatesModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }
}
