import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../services/authentication/authentication.service";
import {Subject, takeUntil} from "rxjs";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError, MatFormFieldModule} from "@angular/material/form-field";
import { MatInputModule} from "@angular/material/input";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, FormsModule, MatError],
    templateUrl: './login.component.pug',
    styleUrl: './login.component.sass'
})
export class LoginComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    nameFormControl = new FormControl('', [Validators.required, Validators.minLength(3)]);
    passwordFormControl = new FormControl('', [Validators.required]);
    isError: boolean = false;


    // QR-Code Scanner action
    barcode: string = '';
    startTime: number = 0;
    endTime: number = 0;
    @HostListener('document:keydown', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        if (event.key !== 'Shift' && event.key !== 'Enter') {
            this.startTime = new Date().getTime();
            this.barcode += event.key;
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            this.endTime = new Date().getTime();
            if (this.barcode.startsWith('BETON#') && (this.endTime - this.startTime < 10)) {
                const scannedValue = this.barcode.split('BETON#')[1];
                const loginData = scannedValue.split('&#9;');
                const userName = loginData[0].split('Username:')[1];
                const password = loginData[1].split('Password:')[1];
                this.login(userName, password);
                this.barcode = '';
            } else {
                this.barcode = '';
                this.loginUser()
            }

        }
    }

    constructor(private authenticationService: AuthenticationService, private infoMessageService: InfoMessageService, private router: Router) {

    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    loginUser() {
        if (this.nameFormControl.invalid || this.passwordFormControl.invalid) {
            this.nameFormControl.markAsTouched();
            this.passwordFormControl.markAsTouched();
            return;
        }
        if (!this.nameFormControl.value || !this.passwordFormControl.value) {
            this.nameFormControl.markAsTouched();
            this.passwordFormControl.markAsTouched();
            return;
        }
        this.login(this.nameFormControl.value, this.passwordFormControl.value);
    }

    private login(username: string, password: string) {
        this.isError = false;
        this.authenticationService.login({
            username: username,
            password: password
        })
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (success) => {
                    // Handle the success scenario
                    if (success) {
                        // this.infoMessageService.createMessage('Login erfolgreich', 'success');

                    }
                },
                error: (error) => {
                    // Handle the error scenario
                    this.isError = true;
                    // this.infoMessageService.createMessage(error.message, 'error');
                    // You might want to show an error message to the user, for example
                },
                complete: () => {
                    // Handle the completion of the Observable sequence (optional)
                    this.router.navigate(['/administration']);
                }
            });
    }
}
