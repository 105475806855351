import {Component, OnInit} from '@angular/core';
import {DashboardTileComponent} from "../../../shared-components/tiles/dashboard-tile/dashboard-tile.component";
import {AuthenticationService} from "../../../services/authentication/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-production-dashboard',
  standalone: true,
  imports: [DashboardTileComponent],
  templateUrl: './production-dashboard.component.pug',
  styleUrl: './production-dashboard.component.sass'
})
export class ProductionDashboardComponent implements OnInit {
    roles: string[] = [];

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
    }
    ngOnInit() {
        this.roles = this.authenticationService.getRoles();
        if (!this.checkAccess(this.roles)) {
            this.router.navigate(['production/work'])
        }
    }

    private checkAccess(roles: string[]): boolean {
        return roles.includes('Administration') || roles.includes('Planung');
    }

}
