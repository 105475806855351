import {Injectable, Injector} from "@angular/core";
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import {catchError, Observable, of, takeUntil, throwError} from "rxjs";
import {InfoMessageService} from "../../services/infomessage/infomessage.service";
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class AuthenticationInterceptor implements HttpInterceptor {

    private token!: string;

    constructor(private injector: Injector, private infoMessageService: InfoMessageService, private router: Router, private authenticationService: AuthenticationService) {
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            this.infoMessageService.createMessage('Ein unerwarteter Fehler ist aufgetreten, Sie werden zur Sicherheit ausgeloggt', 'info');
            this.authenticationService.logout()
                .subscribe(
                    {
                        next: () => {
                            // this.infoMessageService.createMessage('Sie werden nun ausgeloggt', 'warning');
                        },
                        error: (error) => {
                            // Handle the error scenario
                            this.infoMessageService.createMessage(error.message, 'error');
                            // You might want to show an error message to the user, for example
                        },
                        complete: () => {
                            // Handle the completion of the Observable sequence (optional)
                            // this.router.navigate(['/login']);
                        }
                    },
                )
            return of(err.message);
        }

        if (err.status === 403) {
            this.infoMessageService.createMessage(err.error.message, 'error');
            return of(err.message);
        }
        return throwError(err);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.token = this.injector.get(AuthenticationService).getToken();
        const authenticationRequest = request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + this.token)
        });

        return next.handle(authenticationRequest).pipe(
            catchError(err => this.handleAuthError(err))
        );
    }


}

