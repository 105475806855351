import {Component, OnDestroy, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {DraftService} from "../../../services/draft/draft.service";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {DraftModel} from "../../../models/draft/draft.model";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {Subject, takeUntil} from "rxjs";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";
import {iResponse} from "../../../models/response/response.model";

@Component({
    selector: 'app-archive-draft',
    standalone: true,
    imports: [RouterLink, ReactiveFormsModule, RoundedButtonComponent],
    templateUrl: './archive-draft.component.pug',
    styleUrl: './archive-draft.component.sass'
})
export class ArchiveDraftComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    archivedDrafts: DraftModel[] = [];
    filteredArchive: DraftModel[] = [];
    filterForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
    })
    filterTimeout: any;
    constructor(
        private draftService: DraftService,
        private infoMessageService: InfoMessageService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.getDraftArchive();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    filterList(listToFilter: DraftModel[]) {
        const filterValue = this.filterForm.get('name')?.value.toLowerCase();
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            this.filteredArchive = listToFilter.filter(draft => draft.name.toLowerCase().includes(filterValue));
        }, 500)
    }

    redo(draft: DraftModel) {
        this.openConfirmDialog('Wiederherstellen', `Wollen Sie diese Vorlage "${draft.name}" wirklich wiederherstellen?`, draft, 'restore');
    }

    delete(draft: DraftModel) {
        this.openConfirmDialog('Löschen', `Wollen Sie diese Vorlage "${draft.name}" wirklich löschen?`, draft, 'delete');
    }

    private getDraftArchive() {
        this.draftService.getDeletedDrafts()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: async (response) => {
                    this.archivedDrafts = [...response.data];

                    for (let i: number = 0; i < this.archivedDrafts.length; i++) for (let draft of this.archivedDrafts) {
                        try {
                            await this.restoreDraft(draft, i); // Wait for each draft to be restored
                        } catch (error) {
                            // Handle the error if needed
                            console.error('Error restoring draft:', error);
                        }
                    }

                    this.filteredArchive = [...this.archivedDrafts]; // Update filteredArchive after all drafts are restored
                },
                error: (err) => {
                    this.infoMessageService.createMessage(err, 'error');
                }
            });
    }

    private deleteDraft(draft: DraftModel) {
        this.draftService.deleteDraftFinal(draft.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    let foundIndex = this.archivedDrafts.findIndex(x => x.uuid === draft.uuid);
                    this.archivedDrafts.splice(foundIndex, 1);
                    this.filteredArchive = [...this.archivedDrafts];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    private restoreDraft(draft: DraftModel, i: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.draftService.getDraft(draft.uuid)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response: iResponse<DraftModel>) => {
                        this.archivedDrafts[i] = response.data;
                        if (!this.archivedDrafts[i].workflows) {
                            this.archivedDrafts[i].workflows = [];
                        } else {
                            this.archivedDrafts[i].workflows.forEach(wfl => {
                                wfl.picture = null;
                            });
                        }
                        resolve(); // Resolve the promise when the draft is restored
                    },
                    error: (error) => {
                        this.infoMessageService.createMessage(error, 'error');
                        reject(error); // Reject the promise in case of error
                    }
                });
        });
    }


    private openConfirmDialog(title: string, message: string, draft: DraftModel, type: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: title,
                message: message
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                switch (type) {
                    case 'delete':
                        this.deleteDraft(draft);
                        break;
                    case 'restore':
                        this.update(draft);
                        break;
                }
            }
        });
    }

    update(draft: DraftModel) {
        draft.deletedAt = null;
        draft.workflowUuids = draft.workflows.map(workflow => workflow.uuid);
        this.draftService.updateDraft(draft)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    let foundIndex = this.archivedDrafts.findIndex(x => x.uuid === draft.uuid);
                    this.archivedDrafts.splice(foundIndex, 1);
                    this.filteredArchive = [...this.archivedDrafts];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            });
    }
}
