import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {NavigationComponent} from "./components/navigation/navigation.component";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {filter} from "rxjs";
import {HttpClientModule} from "@angular/common/http";
import {AuthenticationService} from "../services/authentication/authentication.service";
import {HeaderComponent} from "./components/header/header.component";
import {environment} from "../environments/environment";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, NavigationComponent, HttpClientModule, HeaderComponent],
    templateUrl: './app.component.pug',
    styleUrl: './app.component.sass',
    // providers: [AuthenticationService]
})
export class AppComponent implements OnInit{
    isMobile: boolean = false;
    isMobileDevice: boolean = false;
    mainLayout = false;
    activeRoute: string = '';
    reducedLayoutPathSegments: string[] = ['no-permission', 'not-found', 'login'];
    isLoggedIn: boolean|undefined = false;
    environment = environment;



    constructor(
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        this.initBreakpointObserver();
        this.initRouteListener();
    }

    ngOnInit() {
        this.isLoggedIn = this.authenticationService.isAuthenticated();
    }

    private initBreakpointObserver() {
        this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(result => {
            this.isMobile = result.matches;
            this.isMobileDevice = this.checkIfMobileDevice();
        })
    }

    private checkIfMobileDevice(): boolean {
        const userAgent = window.navigator.userAgent;
        return /iPhone|iPad|iPod|Android/i.test(userAgent);
    }

    private initRouteListener() {
        this.router.events.pipe(
            filter((routerEvent: any): routerEvent is NavigationEnd => {
                return routerEvent instanceof NavigationEnd;
            })
        ).subscribe((event: NavigationEnd) => {
                this.activeRoute = event.url;
                this.mainLayout = !this.reducedLayoutPathSegments.some((segment: string) => {
                    return event.urlAfterRedirects.includes(segment)
                })
            }
        );
    }

    setFullscreen() {
        const container = document.getElementById('fullscreenTrigger')
        if (!document.fullscreenElement) {
            container!.requestFullscreen().catch(err => {
                alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen();
        }
    }
}
