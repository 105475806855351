import {Routes} from '@angular/router';
import {PlanningComponent} from "./components/planning/planning.component";
import {ProductionComponent} from "./components/production/production.component";
import {WorkflowComponent} from "./components/workflow/workflow.component";
import {ComponentComponent} from "./components/component/component.component";
import {DraftComponent} from "./components/draft/draft.component";
import {CategoryComponent} from "./components/category/category.component";
import {AdministrationComponent} from "./components/administration/administration.component";
import {ArchiveComponent} from "./components/archive/archive.component";
import {ArchiveCategoryComponent} from "./components/archive-category/archive-category.component";
import {ArchiveDraftComponent} from "./components/archive-draft/archive-draft.component";
import {ArchiveWorkflowComponent} from "./components/archive-workflow/archive-workflow.component";
import {PlanningDashboardComponent} from "./components/planning-dashboard/planning-dashboard.component";
import {PlanningAdministrationComponent} from "./components/planning-administration/planning-administration.component";
import {LoginComponent} from "./components/login/login.component";
import {authenticationGuard} from "../guards/authentication/authentication.guard";
import {roleGuard} from "../guards/role/role.guard";
import {routerGuard} from "../guards/router/router.guard";
import {loggedInGuard} from "../guards/logged-in/logged-in.guard";
import {unsavedChangesGuard} from "../guards/unsaved-changes/unsaved-changes.guard";
import {ProductionArchiveComponent} from "./components/production-archive/production-archive.component";
import {ProductionDashboardComponent} from "./components/production-dashboard/production-dashboard.component";
import {ProductionInProgressComponent} from "./components/production-in-progress/production-in-progress.component";
import {ProductionSolvedComponent} from "./components/production-solved/production-solved.component";
import {MapComponent} from "./components/map/map.component";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {MapHedgehogComponent} from "./components/map-hedgehog/map-hedgehog.component";
import {UserAdministrationComponent} from "./components/user-administration/user-administration.component";

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
        title: 'Beton Müller'
    },
    {
        path: 'login',
        component: LoginComponent,
        title: 'Beton Müller',
        canActivate: [loggedInGuard]
    },
    {
        path: 'administration',
        component: AdministrationComponent,
        title: 'Beton Müller Administration',
        canActivate: [authenticationGuard, routerGuard, roleGuard],
        data: {
            role: ['Administration'],
        }
    },
    {
        path: 'administration/category',
        component: CategoryComponent,
        title: 'Beton Müller Administration',
        canActivate: [authenticationGuard, roleGuard],
        canDeactivate: [unsavedChangesGuard],
        data: {
            role: ['Administration'],
        }
    },
    {
        path: 'administration/category/workflow/:uuid',
        component: WorkflowComponent,
        title: 'Beton Müller Administration',
        canActivate: [authenticationGuard, roleGuard],
        canDeactivate: [unsavedChangesGuard],
        data: {
            role: ['Administration']
        }
    },
    {
        path: 'administration/draft',
        component: DraftComponent,
        title: 'Beton Müller Administration',
        canActivate: [authenticationGuard, roleGuard],
        canDeactivate: [unsavedChangesGuard],
        data: {
            role: ['Administration']
        }
    },
    {
        path: 'administration/archive',
        component: ArchiveComponent,
        title: 'Beton Müller Administration',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration']
        }
    },
    {
        path: 'administration/archive/category',
        component: ArchiveCategoryComponent,
        title: 'Beton Müller Administration',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration']
        }
    },
    {
        path: 'administration/archive/workflow',
        component: ArchiveWorkflowComponent,
        title: 'Beton Müller Administration',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration']
        }
    },
    {
        path: 'administration/archive/draft',
        component: ArchiveDraftComponent,
        title: 'Beton Müller Administration',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration']
        }
    },
    {
        path: 'administration/archive/production',
        component: ProductionArchiveComponent,
        title: 'Beton Müller Administration',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration']
        }
    },
    {
        path: 'user-management',
        component: UserAdministrationComponent,
        title: 'Beton Müller Nutzerverwaltung',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration']
        }
    },
    {
        path: 'planning',
        component: PlanningDashboardComponent,
        title: 'Beton Müller Planung',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration', 'Planung']
        }
    },
    {
        path: 'planning/create',
        component: PlanningComponent,
        title: 'Beton Müller Planung',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration', 'Planung']
        }
    },
    {
        path: 'planning/create/component/:orderNumber/:shaftNumber',
        component: ComponentComponent,
        title: 'Beton Müller Planung',
        canActivate: [authenticationGuard, roleGuard],
        canDeactivate: [unsavedChangesGuard],
        data: {
            role: ['Administration', 'Planung']
        }
    },
    {
        path: 'planning/planned',
        component: PlanningAdministrationComponent,
        title: 'Beton Müller Planung',
        canActivate: [authenticationGuard, roleGuard],
        canDeactivate: [unsavedChangesGuard],
        data: {
            role: ['Administration', 'Planung']
        }
    },
    {
        path: 'production',
        component: ProductionDashboardComponent,
        title: 'Beton Müller Produktion',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration', 'Planung', 'Produktion', 'Endkontrolle', 'Logistik']
        }
    },
    {
        path: 'production/work',
        component: ProductionComponent,
        title: 'Beton Müller Produktion',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration', 'Planung', 'Produktion', 'Endkontrolle', 'Logistik']
        }
    },
    {
        path: 'production/work/map',
        component: MapComponent,
        title: 'Beton Müller Produktion',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration', 'Planung', 'Produktion', 'Endkontrolle', 'Logistik']
        }
    },
    {
        path: 'production/work/map-hedgehog',
        component: MapHedgehogComponent,
        title: 'Beton Müller Produktion',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration', 'Planung', 'Produktion', 'Logistik']
        }
    },
    {
        path: 'production/in-progress',
        component: ProductionInProgressComponent,
        title: 'Beton Müller Produktion',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration', 'Planung']
        }
    },
    {
        path: 'production/solved',
        component: ProductionSolvedComponent,
        title: 'Beton Müller Produktion',
        canActivate: [authenticationGuard, roleGuard],
        data: {
            role: ['Administration', 'Planung']
        }
    },
    {
      path: 'not-found',
      component: NotFoundComponent,
      title: 'Seite nicht gefunden'
    },
    {
        path: '**',
        redirectTo: 'not-found',
        title: 'Seite nicht gefunden'
    }
];
