import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {HttpService} from "../http/http.service";
import {iResponse} from "../../models/response/response.model";
import {catchError} from "rxjs/operators";
import {VersionModel} from "../../models/version/version.model";

interface VersionInfo {
    version: string;
    info: string;
}

@Injectable({providedIn: 'root'})
export class VersionService extends HttpService {

    public getVersionInfo(): Observable<VersionInfo> {
        return this.http.get<VersionInfo>('environments/version.json');
    }

    getVersionByUserId(uuid: string): Observable<iResponse<VersionModel>> {
        return this.get(`version/${uuid}`).pipe(
            map((response: iResponse<VersionModel>) => {
                return response
            }),
            catchError(error => {
                throw error
            })
        )
    }

    setVersionByUserId(uuid: string, version: string): Observable<iResponse<VersionModel>> {
        return this.post(`version`, {userUuid: uuid, lastVersionRead: version}).pipe(
            map((response: iResponse<VersionModel>) => {
                return response
            }),
            catchError(error => {
                throw error
            })
        )
    }
}
