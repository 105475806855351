import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from "../../../services/user/user.service";
import {Subject, takeUntil} from "rxjs";
import {UserModel} from "../../../models/user/user.model";
import {iResponse, iResponseList} from "../../../models/response/response.model";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {RoleService} from "../../../services/role/role.service";
import {RoleModel} from "../../../models/role/role.model";
import {AddUserModalComponent} from "../add-user-modal/add-user-modal.component";
import {ChangePasswordModalComponent} from "../change-password-modal/change-password-modal.component";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";

@Component({
  selector: 'app-user-administration',
  standalone: true,
  imports: [RoundedButtonComponent, ReactiveFormsModule],
  templateUrl: './user-administration.component.pug',
  styleUrl: './user-administration.component.sass'
})
export class UserAdministrationComponent implements OnInit, OnDestroy{
    private destroy$ = new Subject<void>();
    filteredUserList: UserModel[] = [];
    userList: UserModel[] = [];

    roles: RoleModel[] = [];

    filterForm: FormGroup = new FormGroup<any>({
        text: new FormControl<string>(''),
    })
    userForm: FormGroup = new FormGroup<any>({
        id: new FormControl<string>(''),
        firstName: new FormControl<string>(''),
        lastName: new FormControl<string>(''),
        username: new FormControl<string>(''),
        roleUuid: new FormControl<string>(''),
    })

    filterTimeout: any;
    constructor(
        private userService: UserService,
        private roleService: RoleService,
        private dialog: MatDialog,
        private infoMessageService: InfoMessageService
    ) {

    }

    ngOnInit() {
        this.getUserList()
        this.getRoles()
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    activateEditUser(user: UserModel) {
        if (this.userForm.get('id')?.value !== user.id) {
            this.userForm.get('id')?.setValue(user.id);
            this.userForm.get('firstName')?.setValue(user.firstName);
            this.userForm.get('lastName')?.setValue(user.lastName);
            this.userForm.get('username')?.setValue(user.username);
            this.userForm.get('roleUuid')?.setValue(user.realmRoles![0]);
        }
    }

    resetUserForm() {
        this.userForm.reset();
        this.userForm.get('id')?.patchValue('');
    }

    openDeleteUser(user: UserModel) {
        this.openConfirmDialog('Löschen', 'Wollen Sie den Nutzer "' + user.firstName + ' ' + user.lastName + '" wirklich löschen?', user, 'delete');
    }

    triggerUpdateUser() {
        if (!this.userForm.valid) {
            this.userForm.markAllAsTouched();
            return
        }
        const updatedUser: UserModel = {
            id: this.userForm.get('id')?.value,
            firstName: this.userForm.get('firstName')?.value,
            lastName: this.userForm.get('lastName')?.value,
            username: this.userForm.get('username')?.value,
            realmRoles: [this.userForm.get('roleUuid')?.value],
            createdTimestamp: null,
            enabled: true,
            email: null,
            groups: null,
            password: null
        }
        this.updateUser(updatedUser)
    }

    openAddUserModal() {
        const dialogRef = this.dialog.open(AddUserModalComponent, {
            data: {
                roles: this.roles
            }
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getUserList()
            }
        })
    }

    openChangePasswordModal(user: UserModel) {
        const dialogRef = this.dialog.open(ChangePasswordModalComponent, {
            data: user
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.infoMessageService.createMessage('Passwort aktualisiert', 'info')
                this.getUserList()
            }
        })
    }


    private openConfirmDialog(title: string, message: string, user: UserModel, type: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: title,
                message: message
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                switch (type) {
                    case 'delete':
                        this.deleteUser(user)
                        break;
                    default:
                        break;
                }
            }
        });
    }

    private deleteUser(user: UserModel) {
        this.userService.deleteUser(user.id!)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.getUserList();
                },
                error: (error) => {
                    console.log(error)
                }
            })
    }

    private getUserList() {
        this.userService.getUserList()
            .pipe(takeUntil(this.destroy$))
            .subscribe( {
                next: (response: iResponseList<UserModel>) => {
                    this.userList = response.data
                    this.filteredUserList = [...response.data]
                },
                error: (error) => {
                    console.log(error)
                }
            })
    }

    private updateUser(user: UserModel) {
        this.userService.updateUser(user)
            .pipe(takeUntil(this.destroy$))
            .subscribe( {
                next: (response: iResponse<UserModel>) => {
                    this.getUserList();
                },
                error: (error) => {
                    console.log(error)
                }
            })
    }

    private getRoles() {
        this.roleService.getRoles()
            .pipe(takeUntil(this.destroy$))
            .subscribe( {
                next: (response: iResponseList<RoleModel>) => {
                    this.roles = response.data.filter(
                        (role: RoleModel) => role.name !== 'uma_authorization' && role.name !== 'default-roles-beton-mueller' && role.name !== 'offline_access'
                    );
                },
                error: (error) => {
                    console.log(error)
                }
            })
    }
}
