import {Injectable} from "@angular/core";
import {HttpService} from "../http/http.service";
import {iResponse, iResponseList} from "../../models/response/response.model";
import {CategoryModel} from "../../models/category/category.model";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {SubcategoryModel} from "../../models/subcategory/subcategory.model";

@Injectable({providedIn: 'root'})
export class CategoryService extends HttpService {

    getCategories(): Observable<iResponseList<CategoryModel>> {
        return this.get('category').pipe(
            map((response: iResponseList<CategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getCategory(uuid: string): Observable<iResponse<CategoryModel>> {
        return this.get(`category/${uuid}`).pipe(
            map((response: iResponse<CategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getDeletedCategories(): Observable<iResponseList<CategoryModel>> {
        return this.get('category/deleted').pipe(
            map((response: iResponseList<CategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getCategoriesWithDeletedWorkflows(): Observable<iResponseList<CategoryModel>> {
        return this.get('category/deleted-workflows').pipe(
            map((response: iResponseList<CategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    addCategory(data: CategoryModel): Observable<iResponse<CategoryModel>> {
        return this.post('category', data).pipe(
            map((response: iResponse<CategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        );
    }

    updateCategory(data: CategoryModel): Observable<iResponse<CategoryModel>> {
        return this.put('category', data).pipe(
            map((response: iResponse<CategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        );
    }

    deleteCategory(uuid: string): Observable<iResponse<CategoryModel>> {
        return this.delete(`category/${uuid}`).pipe(
            map((response: iResponse<CategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )

    }

    deleteCategoryFinal(uuid: string): Observable<iResponse<CategoryModel>> {
        return this.delete(`category/${uuid}/delete`).pipe(
            map((response: iResponse<CategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    checkDuplicates(name: string, uuid?: string):Observable<iResponse<boolean>> {
        return this.put(`category/check-duplicates`, {name}).pipe(
            map((response: iResponse<boolean>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }


    // Sub Categories

    getSubCategories(CategoryUuid: string): Observable<iResponseList<SubcategoryModel>> {
        return this.get(`subcategory/${CategoryUuid}`).pipe(
            map((response: iResponseList<SubcategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getSubCategoryByUuid(uuid: string): Observable<iResponse<SubcategoryModel>> {
        return this.get(`subcategory/byId/${uuid}`).pipe(
            map((response: iResponse<SubcategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    addSubcategoryToCategory(categoryUuid: string, subcategory: SubcategoryModel): Observable<iResponse<SubcategoryModel>> {
        return this.post(`subcategory/${categoryUuid}`, subcategory).pipe(
            map((response: iResponse<SubcategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    checkSubcategoryDuplicates(name: string, categoryUuid: string):Observable<iResponse<boolean>> {
        return this.put(`subcategory/check-duplicates`, {name, categoryUuid}).pipe(
            map((response: iResponse<boolean>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    deleteSubcategory(uuid: string): Observable<iResponse<SubcategoryModel>> {
        return this.delete(`subcategory/${uuid}`).pipe(
            map((response: iResponse<SubcategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    updateSubcategory(data: SubcategoryModel): Observable<iResponse<SubcategoryModel>> {
        return this.put('subcategory', data).pipe(
            map((response: iResponse<SubcategoryModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }
}
