import {Injectable} from "@angular/core";
import {HttpService} from "../http/http.service";
import {iResponse, iResponseList} from "../../models/response/response.model";
import {WorkflowModel} from "../../models/workflow/workflow.model";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class WorkflowService extends HttpService {
    getWorkflows(): Observable<iResponseList<WorkflowModel>> {
        return this.get('workflow').pipe(
            map((response: iResponseList<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getWorkflow(uuid: string): Observable<iResponse<WorkflowModel>> {
        return this.get(`workflow/${uuid}`).pipe(
            map((response: iResponse<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getStandardWorkflows(): Observable<iResponseList<WorkflowModel>> {
        return this.get('workflow/standard').pipe(
            map((response: iResponseList<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getWorkflowListBySubCategory(uuid: string): Observable<iResponseList<WorkflowModel>> {
        return this.get(`workflow/subcategory/${uuid}`).pipe(
            map((response: iResponseList<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    getDeletedWorkflowsByCategory(uuid: string): Observable<iResponseList<WorkflowModel>> {
        return this.get(`workflow/subcategory/${uuid}/deleted`).pipe(
            map((response: iResponseList<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    addWorkflow(data: WorkflowModel, subcategoryUuid: string): Observable<iResponse<WorkflowModel>> {
        return this.post(`workflow/${subcategoryUuid}`, data).pipe(
            map((response: iResponse<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    updateWorkflow(data: WorkflowModel): Observable<iResponse<WorkflowModel>> {
        return this.put('workflow', data).pipe(
            map((response: iResponse<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    deleteWorkflow(uuid: string): Observable<iResponse<WorkflowModel>> {
        return this.delete(`workflow/${uuid}`).pipe(
            map((response: iResponse<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    deleteWorkflowFinal(uuid: string): Observable<iResponse<WorkflowModel>> {
        return this.delete(`workflow/${uuid}/delete`).pipe(
            map((response: iResponse<WorkflowModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    checkDuplicates(name: string, subcategoryUuid: string):Observable<iResponse<boolean>> {
        return this.put(`workflow/check-duplicates`, {name, subcategoryUuid}).pipe(
            map((response: iResponse<boolean>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }
}
