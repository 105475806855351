import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {Location} from "@angular/common";

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [],
  templateUrl: './map.component.pug',
  styleUrl: './map.component.sass'
})
export class MapComponent implements AfterViewInit {
    @ViewChild('mapCanvas') mapCanvas!: ElementRef<HTMLCanvasElement>;
    @ViewChild('mapContainer') mapContainer!: ElementRef;
    private canvasContext!: CanvasRenderingContext2D;
    private topLeftGeo = {lat: 48.64588, lng:  8.05890}
    private bottomRightGeo = {lat: 48.64378,  lng: 8.06339}


    constructor(
        private location: Location
    ) {
    }

    ngAfterViewInit(): void {
        this.canvasContext = this.mapCanvas.nativeElement.getContext('2d')!;
        this.loadAndDrawMap();
    }

    private loadAndDrawMap(): void {
        const image = new Image();
        image.src = 'assets/map/apple_maps_100.jpg';
        image.onload = () => {
            // Get the container dimensions
            const containerWidth = this.mapContainer.nativeElement.offsetWidth;
            const containerHeight = this.mapContainer.nativeElement.offsetHeight;

            // Calculate scale factors for width and height
            const scaleWidth = containerWidth / image.width;
            const scaleHeight = containerHeight / image.height;

            // Use the smaller scale factor to keep the entire image in view
            const scale = Math.min(scaleWidth, scaleHeight);

            // Calculate the new image size
            const scaledWidth = image.width * scale;
            const scaledHeight = image.height * scale;

            // Set canvas dimensions to match the scaled image
            this.mapCanvas.nativeElement.width = scaledWidth;
            this.mapCanvas.nativeElement.height = scaledHeight;

            // Center the image in the container
            const offsetX = (containerWidth - scaledWidth) / 200;
            const offsetY = (containerHeight - scaledHeight) / 200;

            // Draw the image scaled and centered
            this.canvasContext.drawImage(image, offsetX, offsetY, scaledWidth, scaledHeight);
            // const state = this.location.getState();
            // @ts-ignore
            // this.drawIcon(state.latitude, state.longitude);
            this.drawIcon(48.64546, 8.06046);// 48,64546° N, 8,06046° O
        };
    }

    drawIcon(lat: number, lng: number): void {
        const icon = new Image();
        icon.src = 'assets/fonts/icon/location.svg'; // Ensure this path is correct
        icon.onload = () => {
            const point = this.geoToPx(lat, lng);

            // Dynamically adjust scale based on the device and screen size
            const scale = this.calculateDynamicScale();

            // Scale the icon size
            const scaledWidth = icon.width * scale;
            const scaledHeight = icon.height * scale;

            // Calculate the x and y to center the scaled icon on the point
            const x = point.x - (scaledWidth / 2);
            const y = point.y - (scaledHeight / 2);

            this.canvasContext.drawImage(icon, x, y, scaledWidth, scaledHeight);
        };
    }

    calculateDynamicScale(): number {
        const baseScale = 0.2; // Starting point for scaling
        const canvasWidth = this.mapCanvas.nativeElement.width;
        const canvasHeight = this.mapCanvas.nativeElement.height;
        const devicePixelRatio = window.devicePixelRatio || 1;

        // Example of adjusting base scale based on canvas size and device pixel ratio
        const responsiveScale = Math.min(canvasWidth, canvasHeight) / 1000 * devicePixelRatio * baseScale;

        // Further refinement for mobile devices
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        if (isMobile) {
            // Reduce scale for mobile devices
            return responsiveScale * 0.2; // Adjust this factor based on testing
        }

        return responsiveScale;
    }

    private geoToPx(lat: number, lng: number): { x: number, y: number } {
        const xScale = (this.mapCanvas.nativeElement.width / (this.bottomRightGeo.lng - this.topLeftGeo.lng));
        const yScale = (this.mapCanvas.nativeElement.height / (this.topLeftGeo.lat - this.bottomRightGeo.lat));
        const pixelY = (this.topLeftGeo.lat - lat) * yScale;
        const pixelX = (lng - this.topLeftGeo.lng) * xScale;
        return {x: pixelX, y: pixelY};
    }

}
