import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BreadcrumbService} from "../../../services/breadcrumb/breadcrumb.service";
import {BreadcrumbPipe} from "../../../pipes/breadcrumb/breadcrumb.pipe";
import {RouterLink} from "@angular/router";
import {Subject} from "rxjs";
import {MatTooltipModule} from "@angular/material/tooltip";

interface Breadcrumb {
    label: string;
    url: string;
}

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        BreadcrumbPipe,
        RouterLink,
        MatTooltipModule
    ],
    templateUrl: './header.component.pug',
    styleUrl: './header.component.sass'
})
export class HeaderComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    breadcrumbs: Breadcrumb[] = [];

    constructor(
        private breadcrumbService: BreadcrumbService,
    ) {
    }

    ngOnInit() {
        this.subscribeToBreadcrumbs();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    subscribeToBreadcrumbs() {
        this.breadcrumbService.getBreadcrumbsObservable().subscribe((breadcrumbs: Breadcrumb[]) => {
            this.breadcrumbs = breadcrumbs
        })
    }
}
