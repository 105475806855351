import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from "@angular/forms";
import {ConfirmDialogModel} from "../../../models/confirm-dialog/confirm-dialog.model";
import {MatError} from "@angular/material/form-field";
import {WorkflowService} from "../../../services/workflow/workflow.service";
import {map, Observable, startWith, Subject, takeUntil} from "rxjs";
import {iResponse} from "../../../models/response/response.model";
import {CategoryService} from "../../../services/category/category.service";
import {DraftService} from "../../../services/draft/draft.service";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {start} from "node:repl";
import {WorkflowModel} from "../../../models/workflow/workflow.model";
import {AsyncPipe} from "@angular/common";

export interface AddContentModalData {
    type: string
    selectedOption: any
}
@Component({
    selector: 'app-add-content-modal',
    standalone: true,
    imports: [ReactiveFormsModule, MatError, MatAutocompleteModule, AsyncPipe],
    templateUrl: './add-content-modal.component.pug',
    styleUrl: './add-content-modal.component.sass'
})
export class AddContentModalComponent implements OnDestroy, OnInit {
    private destroy$ = new Subject<void>();
    inputForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>('', Validators.required),
    });

    selectedService: any;
    selectedOption: any | null = null;
    duplicated: boolean = false;

    filteredOptions!: Observable<any>
    workflowList: WorkflowModel[] = [];
    filteredWorkflowList: WorkflowModel[] = [];

    constructor(
        public dialogRef: MatDialogRef<AddContentModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
        private workflowService: WorkflowService,
        private categoryService: CategoryService,
        private draftService: DraftService
    ) {
        if (data.type === 'workflow') {
            this.selectedService = this.workflowService;
            this.getWorkflows();
        }
        if (data.type === 'category') {
            this.selectedService = this.categoryService;
        }
        if (data.type === 'draft') {
            this.selectedService = this.draftService;
        }
        if (data.type === 'subcategory') {
            this.selectedService = this.categoryService;
        }
    }

    ngOnInit() {
        this.filteredOptions = this.inputForm.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        )
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onConfirm(): void {
        if (!this.inputForm.valid) {
            this.inputForm.markAllAsTouched();
            return;
        }

        console.log(this.selectedOption);
        console.log(this.inputForm.value.name);
        if (this.selectedOption && this.selectedOption.name == this.inputForm.get('name')?.value) {
            const result: AddContentModalData = {
                type: 'selected',
                selectedOption: this.selectedOption
            }
            console.log('return with object');
            this.dialogRef.close(result);
        } else {
            const result: AddContentModalData = {
                type: 'name',
                selectedOption: this.inputForm.value.name

            }
            console.log('return with name');
            this.checkDuplicates(result)

        }
    }

    onClose(): void {
        this.dialogRef.close();
    }

    private checkDuplicates(result: AddContentModalData) {
        this.duplicated = false;
        if (this.data.type === 'subcategory') {
            this.selectedService.checkSubcategoryDuplicates(result.selectedOption, this.data.uuid!)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response: iResponse<boolean>) => {
                        if (response.data === true) {
                            this.inputForm.markAllAsTouched();
                            this.duplicated = true;
                        } else {
                            this.dialogRef.close(result);
                        }
                    },
                    error: () => {
                        this.inputForm.get('name')?.setErrors({duplicated: true});
                    }
                })
        } else {
            this.selectedService.checkDuplicates(result.selectedOption, this.data.uuid!)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response: iResponse<boolean>) => {
                        if (response.data === true) {
                            this.inputForm.markAllAsTouched();
                            this.duplicated = true;
                        } else {
                            this.dialogRef.close(result);
                        }
                    },
                    error: () => {
                        this.inputForm.get('name')?.setErrors({duplicated: true});
                    }
                })
        }
    }

    private getWorkflows() {
        this.workflowService.getWorkflows()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.workflowList = [...response.data];
                    this.filteredWorkflowList = [...response.data];
                },
                error: (error) => {
                    console.log('error', error);
                }
            })
    }

    private _filter(value: any): WorkflowModel[] {
        if (typeof value.name !== 'string') {
            return []
        }
        return this.workflowList.filter(option => option.name.toLowerCase().includes(value.name.toLowerCase()));
    }

    displayFn(value: string): string {
        return value || '';
    }

    onOptionSelected(event: any) {
        this.selectedOption = event.option.value;
        this.inputForm.get('name')?.setValue(this.selectedOption.name);

    }
}
