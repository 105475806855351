import {Pipe, PipeTransform} from '@angular/core';
import {BreadcrumbMapping} from "../../models/breadcrumb/breadcrumb.model";

@Pipe({
    name: 'breadcrumb',
    standalone: true
})
export class BreadcrumbPipe implements PipeTransform {

    // This is your mapping from URL segments to custom labels
    breadcrumbMapping: BreadcrumbMapping = {
        'administration': 'Administration',
        'category': 'Kategorien',
        'workflow': 'Arbeitsschritte',
        'draft': 'Vorlagen',
        'archive': 'Archiv',
        'planning': 'Planung',
        'create': 'Produktionsablauf erstellen',
        'planned': 'Produktionsablauf bearbeiten',
        'production': 'Produktion',
        'products-solved': 'Fertiggestellte Produktionsabläufe',
        'products-archive': 'Archivierte Produktionsablauf',
        'in-progress': 'In Bearbeitung',
        'work': 'Bearbeiten',
        'solved': 'Abgeschlossen',
        'user-management': 'Nutzerverwaltung'

        // Add more mappings as needed
    };

    transform(value: string, args?: any): string {
        return this.breadcrumbMapping[value] || value; // Fallback to the original value if no mapping is found
    }
}
