import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(
        protected http: HttpClient
    ) { }

    private createHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Accept': 'application/json',
        });
    }

    get(url: string): Observable<any> {
        return this.http.get(environment.apiUrl + url, { headers: this.createHeaders() })
            .pipe(
                catchError(this.handleError)
            );
    }

    post(url: string, data: any): Observable<any> {
        return this.http.post(environment.apiUrl + url, data, { headers: this.createHeaders() })
            .pipe(
                catchError(this.handleError)
            );
    }

    put(url: string, data: any): Observable<any> {
        return this.http.put(environment.apiUrl + url, data, { headers: this.createHeaders() })
            .pipe(
                catchError(this.handleError)
            );
    }

    delete(url: string): Observable<any> {
        return this.http.delete(environment.apiUrl + url, { headers: this.createHeaders() })
            .pipe(
                catchError(this.handleError)
            );
    }

    // A method to handle errors
    private handleError(error: any): Observable<never> {
        throw error;
    }
}
