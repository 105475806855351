import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {Location} from "@angular/common";

export const loggedInGuard: CanActivateFn = (route, state) => {
    const authService = inject(AuthenticationService);
    const location = inject(Location)
    const router = inject(Router)

    if (authService.isAuthenticated()) {
        authService.logout().subscribe({
            next: () => {
                return true;
            },
            error: () => {
                return false;
            }
        })
        return false
    } else {
        return true;
    }
}
