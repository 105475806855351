import { Injectable } from '@angular/core';
import {Router, NavigationEnd, ActivatedRouteSnapshot} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {Breadcrumb} from "../../models/breadcrumb/breadcrumb.model";

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    private breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);

    constructor(private router: Router) {
        // Generate initial breadcrumbs on service initialization
        this.setBreadcrumbs(this.router.url);

        // Listen to future navigation events to update breadcrumbs
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        // @ts-ignore
        ).subscribe((event: NavigationEnd) => {
            this.setBreadcrumbs(event.urlAfterRedirects);
        });
    }

    private setBreadcrumbs(url: string) {
        const newBreadcrumbs = this.createBreadcrumbs(url);
        this.breadcrumbs.next(newBreadcrumbs);
    }

    private createBreadcrumbs(url: string): Breadcrumb[] {
        const segments = url.split('/').filter(segment => segment);
        const breadcrumbArray: Breadcrumb[] = [];

        let accumulatedPath = '';
        const cutOffPaths = ['component']
        for (let i = 0; i < segments.length; i++) {
            const segment = segments[i];
            // Assume that a segment is dynamic if it's a number or UUID
            const isDynamicSegment = this.isDynamicSegment(segment);
            // Check if the segment is a cut-off point
            if (cutOffPaths.includes(segment)) {
                // accumulatedPath += `/${segment}`;
                // breadcrumbArray.push({ label: segment, url: accumulatedPath });
                break; // Stop processing further segments
            }
            if (!isDynamicSegment) {
                // Regular segment
                accumulatedPath += `/${segment}`;
                breadcrumbArray.push({ label: segment, url: accumulatedPath });
            } else if (breadcrumbArray.length > 0) {
                // Dynamic segment, append to the last breadcrumb's URL
                breadcrumbArray[breadcrumbArray.length - 1].url += `/${segment}`;
            }
        }
        return breadcrumbArray;
    }

    private isDynamicSegment(segment: string): boolean {
        // This regex matches a UUID. Adjust the regex to match your ID pattern
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        // This regex matches a numeric ID
        const numericIdRegex = /^\d+$/;

        return uuidRegex.test(segment) || numericIdRegex.test(segment);
    }

    public getBreadcrumbsObservable() {
        return this.breadcrumbs.asObservable();
    }
}
