import {Component, OnDestroy, OnInit} from '@angular/core';
import {ComponentModel} from "../../../models/component/component.model";
import {ComponentService} from "../../../services/component/component.service";
import { iResponseList} from "../../../models/response/response.model";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {LoaderComponent} from "../loader/loader.component";
import {Router, RouterLink} from "@angular/router";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";

@Component({
    selector: 'app-planning',
    standalone: true,
    imports: [LoaderComponent, ReactiveFormsModule, RouterLink, RoundedButtonComponent],
    templateUrl: './planning.component.pug',
    styleUrl: './planning.component.sass'
})
export class PlanningComponent implements OnInit, OnDestroy{
    private destroy$ = new Subject<void>();
    componentList: ComponentModel[] = [];
    filteredComponentList: ComponentModel[] = [];
    filterForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
    })

    filterTimeout: any;

    constructor(
        private componentService: ComponentService,
        private infoMessageService: InfoMessageService,
        private router: Router
    ) {

    }

    ngOnInit() {
        this.loadComponentList();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }


    filterList() {
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            const filterValue = this.filterForm.get('name')?.value.toLowerCase();

            if (filterValue.length < 3) {
                this.filteredComponentList = [...this.componentList];
                return;
            }
            const lowerCaseFilterValue = filterValue;
            this.filteredComponentList = this.componentList.filter((component: ComponentModel) => {
                return component.orderNumber.toLowerCase().includes(lowerCaseFilterValue) ||
                    (component.shaftNumber && component.shaftNumber.toLowerCase().includes(lowerCaseFilterValue)) ||
                    (component.company && component.company.toLowerCase().includes(lowerCaseFilterValue)) ||
                    (component.buildingSite && component.buildingSite.toLowerCase().includes(lowerCaseFilterValue));
            });
        }, 500);
    }

    loadComponentList() {
        this.componentService.getComponents()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<ComponentModel>) => {
                    this.componentList = response.data;
                    this.filteredComponentList = [...this.componentList];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error as string);
                }
            })
    }

    openComponent(component: ComponentModel) {
        this.router.navigate(['planning/create/component', component.orderNumber, component.shaftNumber]);
    }
}
