import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {InfoMessageComponent} from "../../app/components/infomessage/infomessage.component";

@Injectable({providedIn: 'root'})

export class InfoMessageService {

    constructor(private snackBar: MatSnackBar) {
    }

    createMessage(message: string, type: string = 'success') {
        this.snackBar.openFromComponent(InfoMessageComponent, {
            duration: 3000,
            data: {
                message: message,
                type: type
            },
            verticalPosition: 'bottom',
        })
    }
}
