import {Component, Input, OnInit} from "@angular/core";
import {RouterLink} from "@angular/router";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgClass} from "@angular/common";

@Component({
    selector: 'app-dashboard-tile',
    templateUrl: './dashboard-tile.component.pug',
    standalone: true,
    imports: [RouterLink, MatTooltipModule, NgClass],
})

export class DashboardTileComponent implements OnInit {
    @Input() fontAwesomeIcon: string = '';
    @Input() additionalClass: string = '';
    @Input() tooltip: string = '';
    @Input() routerLink: string = '';
    @Input() name: string = '';

    constructor() {
    }

    ngOnInit(): void {

    }

    itemToTooltip() {
        return `${this.tooltip}`;
    }

}
