import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {filter, Subject, takeUntil} from "rxjs";
import {AuthenticationService} from "../../../services/authentication/authentication.service";
import {MatTooltipModule} from "@angular/material/tooltip";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [RouterLink, MatTooltipModule],
  templateUrl: './navigation.component.pug',
  styleUrl: './navigation.component.sass'
})
export class NavigationComponent implements OnInit, OnDestroy{
    private destroy$: Subject<void> = new Subject<void>();
    activeRoute: string = '';
    activeButton: string = '';

    roles: string[] = [];

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private infoMessageService: InfoMessageService
    ) {
    }

    ngOnInit() {
        this.initRouteListener();
        this.activeRoute = this.router.url.split('/',2)[1];
        this.activeButton = this.activeRoute
        this.roles = this.authenticationService.getRoles();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    addClass(activeButton: string): void {
        this.activeButton = activeButton
    }

    removeClass(): void {
        this.activeButton = this.activeRoute
    }

    logout() {
        this.authenticationService.logout()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                {
                    next: () => {
                        // this.infoMessageService.createMessage('Sie werden nun ausgeloggt', 'warning');
                    },
                    error: (error) => {
                        // Handle the error scenario
                        this.infoMessageService.createMessage(error.message, 'error');
                        // You might want to show an error message to the user, for example
                    },
                    complete: () => {
                        // Handle the completion of the Observable sequence (optional)
                        // this.router.navigate(['/login']);
                    }
                },
            )
    }

    private initRouteListener() {
        this.router.events.pipe(
            filter((routerEvent): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd),
        ).subscribe((event: NavigationEnd) => {
            let route = event.urlAfterRedirects;
            let splitRoute = route.split('/', 2);
            this.activeRoute = splitRoute[1];
            this.activeButton = this.activeRoute
        })
    }

}
