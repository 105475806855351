import {Injectable, NgZone} from "@angular/core";
import {HttpService} from "../http/http.service";
import {AuthenticationModel} from "../../models/authentication/authentication.model";
import {Storage} from "../../util/session-db/model/storage";
import {SessionDb} from "../../util/session-db/session.db";
import {HttpClient} from "@angular/common/http";
import {map, Observable, Subject} from "rxjs";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class AuthenticationService extends HttpService {
    authenticationHolder!: Storage<AuthenticationModel> | undefined;
    tokenRefreshTimeout: any = null;
    expiresAt: number = 0;
    roles: string[] = [];
    loggedIn: Subject<boolean> = new Subject<boolean>();
    userUuid: string = '';

    constructor(protected override http: HttpClient,
                protected sessionDb: SessionDb,
                private ngZone: NgZone,
                private router: Router) {
        super(http);
        this.initLoginStorage();
        this.init();
    }

    init() {
        this.decodeToken();
    }

    isAuthenticated(): boolean | undefined {
        if (this.authenticationHolder?.hasObject()) {
            const currentTime = Date.now();
            const expiresAt = this.expiresAt;
            if (currentTime > expiresAt) {
                this.authenticationHolder?.deleteObject();
            }
        }
        return this.authenticationHolder?.hasObject();
    }

    getToken(): string {
        return <string>this.authenticationHolder?.getObject().access_token;
    }

    getUserId(): string {
        return this.userUuid;
    }

    hasRole(role: string[]): boolean {
        return role.some(r => this.roles.includes(r));
    }

    getRoles(): string[] {
        return this.roles;
    }

    getRefreshToken(): string {
        return <string>this.authenticationHolder?.getObject().refresh_token;
    }

    login(credentials: any): Observable<boolean> {
        return this.post('auth/login', credentials).pipe(
            map(response => {
                if (response.success) {
                    let loginData: AuthenticationModel = new AuthenticationModel();
                    loginData.access_token = response.data.access_token;
                    loginData.refresh_token = response.data.refresh_token;
                    loginData.expires_in = response.data.expires_in;
                    loginData.refresh_expires_in = response.data.refresh_expires_in;
                    loginData.token_type = response.data.token_type;
                    loginData.session_state = response.data.session_state;
                    loginData.scope = response.data.scope;
                    this.setSessionData(loginData);
                    return true;
                } else {
                    // Handle the case where response.success is false
                    // You might want to throw an error or handle it differently
                    throw new Error(response.message.data);
                }
            }),
            catchError(error => {
                // Handle errors if necessary or re-throw them
                throw error;
            })
        );
    }

    logout() {
        const postBody = {
            refreshToken: this.getRefreshToken()
        };
        return this.post('auth/logout', postBody).pipe(
            map(response => {
                if (response.success) {
                    this.authenticationHolder?.deleteObject();
                    this.expiresAt = 0;
                    clearTimeout(this.tokenRefreshTimeout);
                    this.router.navigate(['/login']);
                    return true;
                } else {
                    this.authenticationHolder?.deleteObject();
                    this.expiresAt = 0;
                    clearTimeout(this.tokenRefreshTimeout);
                    window.location.href = '/login';
                    // Handle the case where response.success is false
                    // You might want to throw an error or handle it differently
                    throw new Error(response.message.data);
                }
            })
        )
    }

    private setSessionData(loginData: AuthenticationModel): void {
        this.authenticationHolder?.setObject(loginData);
        this.decodeToken();
    }


    private decodeToken() {
        if (this.authenticationHolder?.hasObject()) {
            let tokenData = this.authenticationHolder.getObject();

            if (!tokenData.access_token) {
                return;
            }

            let decoded = this.parseJWT(tokenData.access_token);
            this.userUuid = decoded.sub;
            this.roles = decoded.realm_access.roles;
            this.expiresAt = decoded.exp * 1000;
            this.loggedIn.next(true);
            this.enableTokenRefresh();
            this.checkIfTokenIsExpired();

        }
    }

    checkIfTokenIsExpired(): void {
        let now = Date.now();
        if (now > this.expiresAt) {
            this.logout().subscribe({
                next: () => {
                },
                error: (error) => {
                }
            });
        }
    }

    private enableTokenRefresh() {
        let refreshDelay = this.getRefreshDelay();
        if (this.tokenRefreshTimeout !== null) {
            clearTimeout(this.tokenRefreshTimeout);
        }
        // Needs to run Outside of the main-zone, as it blocks protractor-specs
        // This disables default Angular change-detection, but the result of this request does not need change detection
        // because each request reads the token from the storage by itself
        this.ngZone.runOutsideAngular(() => {
            this.tokenRefreshTimeout = window.setTimeout(() => {
                this.tokenRefreshTimeout = null;
                this.refreshToken().subscribe(() => {

                    },
                    () => {
                        this.logout().subscribe({
                            next: () => {
                            },
                            error: (error) => {
                            }
                        });
                    }
                );
            }, refreshDelay);
        });
    }

    private getRefreshDelay(): number {
        let milliSecondsBefore = 60 * 1000;
        let now = Date.now();
        let expiresIn = this.expiresAt - now;

        if (expiresIn <= milliSecondsBefore) {
            return 60 * 1000;
        }

        return expiresIn - milliSecondsBefore;
    }


    private parseJWT(token: string) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    private initLoginStorage(): void {
        try {
            this.authenticationHolder = this.sessionDb.getStorage(AuthenticationModel, 'AuthConfig');
        } catch (e) {
            this.authenticationHolder = this.sessionDb.createStorage(AuthenticationModel, 'AuthConfig');
        }
    }

    refreshToken(): Observable<any> {
        const postBody = {
            refreshToken: this.getRefreshToken()
        };

        return this.post('auth/token/refresh', postBody).pipe(
            map(
                (res: any) => {
                    let loginData: AuthenticationModel = new AuthenticationModel();
                    loginData.access_token = res.data.access_token;
                    loginData.refresh_token = res.data.refresh_token;
                    loginData.expires_in = Date.now();
                    this.setSessionData(loginData);
                }),
            catchError(error => {
                // Handle errors if necessary or re-throw them
                throw error;
            })
        );
    }
}
