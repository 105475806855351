import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserModel} from "../../../models/user/user.model";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError} from "@angular/material/form-field";
import {UserService} from "../../../services/user/user.service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-change-password-modal',
  standalone: true,
  imports: [ReactiveFormsModule, MatError],
  templateUrl: './change-password-modal.component.pug',
  styleUrl: './change-password-modal.component.sass'
})
export class ChangePasswordModalComponent implements OnInit, OnDestroy{
    private destroy$ = new Subject<void>();
    user!: UserModel

    passwordForm: FormGroup = new FormGroup<any>({
        password: new FormControl<string>('', Validators.required),
        confirmPassword: new FormControl<string>('', Validators.required),
    })

    passwordMissMatch: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ChangePasswordModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UserModel,
        private userService: UserService
    ) {

    }

    ngOnInit() {
        this.user = this.data;
        this.passwordForm.get('confirmPassword')?.valueChanges.subscribe(() => {
            this.checkIfPasswordConfirms();
        })
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    checkIfPasswordConfirms() {
        this.passwordMissMatch = this.passwordForm.get('password')?.value !== this.passwordForm.get('confirmPassword')?.value;
    }

    onSubmit() {
        if (this.passwordForm.invalid && this.passwordMissMatch) {
            return
        }
        this.changePassword();
    }

    onClose() {
        this.dialogRef.close();
    }

    private changePassword() {
        this.userService.changePassword(this.user.id!, this.passwordForm.get('password')?.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    this.dialogRef.close(true);
                },
                error: (error) => {
                    console.log(error);
                }
            });
    }

}
