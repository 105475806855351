import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ProductionModel} from "../../../models/production/production.model";
import {ProductionService} from "../../../services/production/production.service";
import {Subject, takeUntil} from "rxjs";
import {iResponseList} from "../../../models/response/response.model";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {DatePipe, registerLocaleData} from "@angular/common";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTooltipModule} from "@angular/material/tooltip";
import * as de from '@angular/common/locales/de';
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";

@Component({
  selector: 'app-production-archive',
  standalone: true,
  imports: [MatCheckboxModule, MatTooltipModule, ReactiveFormsModule, RoundedButtonComponent],
  templateUrl: './production-archive.component.pug',
  styleUrl: './production-archive.component.sass'
})
export class ProductionArchiveComponent implements OnInit{
    private destroy$ = new Subject<void>();
    filteredProductionsList: ProductionModel[] = [];
    productionList: ProductionModel[] = [];
    filterForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
    })
    filterTimeout: any;
    selectedProductionForm: FormGroup = new FormGroup<any>({
        uuid: new FormControl<string>(''),
    })

    constructor(
        private productionService: ProductionService,
        private infoMessageService: InfoMessageService
    ) {
        registerLocaleData(de.default);
    }

    ngOnInit() {
        this.getArchivedProductions();
    }


    filterList() {
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            const filterValue = this.filterForm.get('name')?.value.toLowerCase();

            if (filterValue.length < 3) {
                this.filteredProductionsList = [...this.productionList];
                return;
            }
            const lowerCaseFilterValue = filterValue;
            this.filteredProductionsList = this.productionList.filter((production: ProductionModel) => {
                return production.orderNumber.toLowerCase().includes(lowerCaseFilterValue) ||
                    (production.shaftNumber && production.shaftNumber.toLowerCase().includes(lowerCaseFilterValue)) ||
                    (production.company && production.company.toLowerCase().includes(lowerCaseFilterValue)) ||
                    (production.buildingSite && production.buildingSite.toLowerCase().includes(lowerCaseFilterValue));
            });
        }, 500);
    }

    openProduction(production: ProductionModel) {
        if (this.selectedProductionForm.get('uuid')?.value === production.uuid) {
            this.selectedProductionForm.get('uuid')?.setValue('');
        } else {
            this.selectedProductionForm.get('uuid')?.setValue(production.uuid);
            production.workflows.forEach(wfl => {
                const datePipe = new DatePipe('de-DE');
                const formattedDate = datePipe.transform(wfl.doneAt, 'dd.MM.yyyy \'um\' HH:mm:ss');
                const infoText = `Erledigt am ${formattedDate}\nvon ${wfl.user}`;
                wfl.infoText = infoText;
            })
        }
    }

    private getArchivedProductions(): void {
        this.productionService.getArchivedProductions()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (productions: iResponseList<ProductionModel>) => {
                    this.productionList = [...productions.data];
                    this.filteredProductionsList = [...productions.data];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error.message, 'error');
                }

            })
    }
}
