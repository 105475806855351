import {Component, OnDestroy, OnInit} from '@angular/core';
import {CategoryService} from "../../../services/category/category.service";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {CategoryModel} from "../../../models/category/category.model";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {RouterLink} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";

@Component({
    selector: 'app-archive-category',
    standalone: true,
    imports: [ReactiveFormsModule, RouterLink, RoundedButtonComponent],
    templateUrl: './archive-category.component.pug',
    styleUrl: './archive-category.component.sass'
})
export class ArchiveCategoryComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    archivedCategories: CategoryModel[] = [];
    filteredArchive: CategoryModel[] = [];
    filterForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
    })
    filterTimeout: any;

    constructor(
        private categoryService: CategoryService,
        private infoMessageService: InfoMessageService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.getCategoryArchive();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    filterList(listToFilter: CategoryModel[]) {
        const filterValue = this.filterForm.get('name')?.value.toLowerCase();
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            this.filteredArchive = listToFilter.filter(category => category.name.toLowerCase().includes(filterValue));
        }, 500)
    }

    redo(category: CategoryModel) {
        this.openConfirmDialog('Wiederherstellen', `Wollen Sie die Kategorie "${category.name}" wirklich wiederherstellen?`, category, 'restore');
    }

    delete(category: CategoryModel) {
        this.openConfirmDialog('Löschen', `Wollen Sie die Kategorie "${category.name}" wirklich löschen?`, category, 'delete');
    }

    private deleteCategory(category: CategoryModel) {
        this.categoryService.deleteCategoryFinal(category.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    let foundIndex = this.archivedCategories.findIndex(x => x.uuid === category.uuid);
                    this.archivedCategories.splice(foundIndex, 1);
                    this.filteredArchive = [...this.archivedCategories];
                    this.infoMessageService.createMessage('Kategorie gelöscht', 'success');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private restoreCategory(category: CategoryModel) {
        category.deletedAt = null;
        this.categoryService.updateCategory(category)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => {
                    let foundIndex = this.archivedCategories.findIndex(x => x.uuid === category.uuid);
                    this.archivedCategories.splice(foundIndex, 1);
                    this.filteredArchive = [...this.archivedCategories];
                    this.infoMessageService.createMessage('Kategorie wiederhergestellt', 'success');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private getCategoryArchive() {
        this.categoryService.getDeletedCategories().pipe(takeUntil(this.destroy$)).subscribe({
            next: (response) => {
                this.archivedCategories = [...response.data];
                this.filteredArchive = [...response.data];
            },
            error: (error) => {
                this.infoMessageService.createMessage(error, 'error')
            }
        })
    }

    private openConfirmDialog(title: string, message: string, category: CategoryModel, type: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: title,
                message: message
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                switch (type) {
                    case 'delete':
                        this.deleteCategory(category);
                        break;
                    case 'restore':
                        this.restoreCategory(category);
                        break;
                }
            }
        });
    }
}
