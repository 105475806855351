import {Injectable} from "@angular/core";
import {AuthenticationService} from "../authentication/authentication.service";
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class PermissionService {
    constructor(private authService: AuthenticationService, private router: Router) {
    }

    routerGuard() {
        if (this.authService.hasRole(['Administration'])) {
            return true
        }
        if (this.authService.hasRole(['Planung'])) {
            this.router.navigate(['/planning']);
            return true
        }
        if (this.authService.hasRole(['Produktion', 'Endkontrolle', 'Logistik'])) {
            this.router.navigate(['/production']);
            return true
        }
        return true
    }

    roleGurad(role: string[]) {
        return !!(this.authService.isAuthenticated() && this.authService.hasRole(role));
    }
}
