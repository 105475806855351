import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WorkflowModel} from "../../../models/workflow/workflow.model";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";

@Component({
  selector: 'app-non-duplicated-workflows',
  standalone: true,
  imports: [RoundedButtonComponent],
  templateUrl: './non-duplicated-workflows.component.pug',
  styleUrl: './non-duplicated-workflows.component.sass'
})
export class NonDuplicatedWorkflowsComponent {
    nonSelectedWorkflows: WorkflowModel[] = [];
    addedWorkflows: WorkflowModel[] = [];

    constructor(
        public dialogRef: MatDialogRef<NonDuplicatedWorkflowsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.nonSelectedWorkflows = this.data.workflows;
    }

    onConfirm(): void {
        this.dialogRef.close(this.addedWorkflows);
    }

    onClose(): void {
        this.dialogRef.close();
    }

    isSelected(workflow: WorkflowModel): boolean {
        return this.addedWorkflows.includes(workflow);
    }

    addWorkflow(workflow: WorkflowModel): void {
        if (this.isSelected(workflow)) {
            this.addedWorkflows = this.addedWorkflows.filter(selectedWorkflow => selectedWorkflow.uuid !== workflow.uuid);
            return;
        }
        this.addedWorkflows.push(workflow);
    }
}
