import {Component, OnDestroy, OnInit} from '@angular/core';
import {CategoryModel} from "../../../models/category/category.model";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {CategoryService} from "../../../services/category/category.service";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {WorkflowModel} from "../../../models/workflow/workflow.model";
import {WorkflowService} from "../../../services/workflow/workflow.service";
import {RouterLink} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";
import {SubcategoryModel} from "../../../models/subcategory/subcategory.model";
import {iResponseList} from "../../../models/response/response.model";
import {MatSelectModule} from "@angular/material/select";
export interface NestedCategories {
    category: CategoryModel
    subCategories: SubcategoryModel[]
}
@Component({
    selector: 'app-archive-workflow',
    standalone: true,
    imports: [ReactiveFormsModule, RouterLink, RoundedButtonComponent, MatSelectModule],
    templateUrl: './archive-workflow.component.pug',
    styleUrl: './archive-workflow.component.sass'
})
export class ArchiveWorkflowComponent implements OnInit, OnDestroy{
    private destroy$ = new Subject<void>();
    archivedWorkflows: WorkflowModel[] = [];
    nestedCategories: NestedCategories[] = [];
    filteredArchive: WorkflowModel[] = [];
    categories: CategoryModel[] = [];
    filterForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
    })
    filterTimeout: any;
    subcategoryForm: FormGroup = new FormGroup<any>({
        uuid: new FormControl<string>(''),
    })

    workflowForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
        uuid: new FormControl<string>(''),
        coordinates: new FormControl<boolean>(false),
        image: new FormControl<boolean>(false),
        categoryUuid: new FormControl<string>(''),
        restrictions: new FormControl<string>(''),
    })

    constructor(
        private categoryService: CategoryService,
        private workflowService: WorkflowService,
        private infoMessageService: InfoMessageService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.getCategories();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    filterList(listToFilter: WorkflowModel[]) {
        const filterValue = this.filterForm.get('name')?.value.toLowerCase();
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            this.filteredArchive = listToFilter.filter(workflow => workflow.name.toLowerCase().includes(filterValue));
        }, 500)
    }

    redo(workflow: WorkflowModel) {
        this.openConfirmDialog('Wiederherstellen', `Wollen Sie den Arbeitsschritt "${workflow.name}" wirklich wiederherstellen?`, workflow, 'restore');
    }

    delete(workflow: WorkflowModel) {
        this.openConfirmDialog('Löschen', `Wollen Sie diesen Arbeitsschritt "${workflow.name}" wirklich löschen?`, workflow, 'delete');
    }

    getWorkflowArchive() {
        this.filterForm.get('name')?.setValue('');
        if (this.subcategoryForm.get('uuid')?.value === '') {
            this.archivedWorkflows = [];
            this.filteredArchive = [];
            return;
        }
        const uuid = this.subcategoryForm.get('uuid')?.value;

        this.workflowService.getDeletedWorkflowsByCategory(uuid).pipe(takeUntil(this.destroy$)).subscribe({
            next: (response) => {
                this.archivedWorkflows = [...response.data];
                this.filteredArchive = [...response.data];
            },
            error: (error) => {
                this.infoMessageService.createMessage(error, 'error')
            }
        })
    }

    private deleteWorkflow(workflow: WorkflowModel) {
        this.workflowService.deleteWorkflow(workflow.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    let foundIndex = this.archivedWorkflows.findIndex(x => x.uuid === workflow.uuid);
                    this.archivedWorkflows.splice(foundIndex, 1);
                    this.filteredArchive = [...this.archivedWorkflows];
                    this.infoMessageService.createMessage('Arbeitsschritt gelöscht', 'success');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private restoreWorkflow(workflow: WorkflowModel) {
        workflow.deletedAt = null;
        this.workflowForm.patchValue(workflow);
        this.workflowService.updateWorkflow(this.workflowForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    let foundIndex = this.archivedWorkflows.findIndex(x => x.uuid === workflow.uuid);
                    this.archivedWorkflows.splice(foundIndex,1);
                    this.filteredArchive = [...this.archivedWorkflows];
                    this.infoMessageService.createMessage('Arbeitsschritt wiederhergestellt', 'success');
                    this.workflowForm.reset();
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private getCategories() {
        this.categoryService.getCategoriesWithDeletedWorkflows()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.categories = [...response.data];
                    this.categories.forEach(category => {
                        this.loadSubcategoriesByCategoryUuid(category)
                    })
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    loadSubcategoriesByCategoryUuid(category: CategoryModel) {
        const uuid = category.uuid;
        if (!uuid) {

            return;
        }

        this.categoryService.getSubCategories(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<SubcategoryModel>) => {
                    this.nestedCategories.push({
                        category: category,
                        subCategories: response.data
                    })
                },
                error: error => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    private openConfirmDialog(title: string, message: string, workflow: WorkflowModel, type: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: title,
                message: message
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                switch (type) {
                    case 'delete':
                        this.deleteWorkflow(workflow);
                        break;
                    case 'restore':
                        this.restoreWorkflow(workflow);
                        break;
                }
            }
        });
    }
}
