import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {ComponentService} from "../../../services/component/component.service";
import {ComponentModel} from "../../../models/component/component.model";
import {iResponse, iResponseList} from "../../../models/response/response.model";
import {CategoryService} from "../../../services/category/category.service";
import {CategoryModel} from "../../../models/category/category.model";
import {WorkflowService} from "../../../services/workflow/workflow.service";
import {WorkflowModel} from "../../../models/workflow/workflow.model";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {ProductionService} from "../../../services/production/production.service";
import {ProductionModel} from "../../../models/production/production.model";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {DraftService} from "../../../services/draft/draft.service";
import {DraftModel} from "../../../models/draft/draft.model";
import {map, Observable, of, Subject, takeUntil} from "rxjs";
import {NonDuplicatedWorkflowsComponent} from "../non-duplicated-workflows/non-duplicated-workflows.component";
import {MatDialog} from "@angular/material/dialog";
import {CanDeactivateType} from "../../../guards/unsaved-changes/unsaved-changes.guard";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {catchError} from "rxjs/operators";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";
import {SubcategoryModel} from "../../../models/subcategory/subcategory.model";
import {MatSelectModule} from "@angular/material/select";

export interface NestedCategories {
    category: CategoryModel
    subCategories: SubcategoryModel[]
}
@Component({
    selector: 'app-component',
    standalone: true,
    imports: [ReactiveFormsModule, CdkDropList, CdkDrag, RouterLink, RoundedButtonComponent, MatSelectModule],
    templateUrl: './component.component.pug',
    styleUrl: './component.component.sass'
})
export class ComponentComponent implements OnDestroy {
    private destroy$ = new Subject<void>();
    component!: ComponentModel;
    categories: CategoryModel[] = [];
    subCategories: SubcategoryModel[] = [];
    workflows: WorkflowModel[] = [];
    workflowsCopy: WorkflowModel[] = [];
    selectedWorkflows: WorkflowModel[] = [];
    standardWorkflows: WorkflowModel[] = [];
    drafts: DraftModel[] = [];
    draftsCopy: DraftModel[] = [];

    productionModel!: ProductionModel

    categoryForm: FormGroup = new FormGroup({
        uuid: new FormControl<string>(''),
    })

    subcategoryForm: FormGroup = new FormGroup({
        uuid: new FormControl<string>(''),
    })

    addWorkflowBool: boolean = false;
    addDraftBool: boolean = false;
    addSubcategoryBool: boolean = false;

    nestedCategories: NestedCategories[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private componentService: ComponentService,
        private categoryService: CategoryService,
        private workflowService: WorkflowService,
        private productionService: ProductionService,
        private infoMessageService: InfoMessageService,
        private router: Router,
        private draftService: DraftService,
        private dialog: MatDialog
    ) {
        this.initRouteListener();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    activateAddDraft() {
        this.addDraftBool = !this.addDraftBool
        if (this.addDraftBool) {
            this.loadDrafts();
        }
    }

    activateAddSubcategory() {
        this.addSubcategoryBool = !this.addSubcategoryBool
        if (!this.addSubcategoryBool) {
            this.subcategoryForm.get('uuid')?.patchValue('');
            this.workflows = [];
        }
    }

    loadCategories() {
        this.categoryService.getCategories()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<CategoryModel>) => {
                    this.categories = response.data;

                    this.categories.forEach(category => {
                        this.loadSubcategoriesByCategoryUuid(category)
                    })

                },
                error: error => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    loadComponent(orderNumber: string, shaftNumber: string) {
        this.componentService.getComponentByOrderAndShaft(orderNumber, shaftNumber)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponse<ComponentModel>) => {
                    this.component = response.data;
                    this.loadCategories();
                    this.getInitialWorkflowList();
                },
                error: error => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    addInitialWorkflows() {
        this.standardWorkflows.forEach(wfl => {
            this.selectWorkflow(wfl, this.selectedWorkflows);
        })
    }

    getInitialWorkflowList() {
        this.getStandardWorkflows().subscribe({
            next: (wfls: WorkflowModel[]) => {
                this.standardWorkflows = wfls;
                this.addInitialWorkflows();
            },
            error: error => {
                this.infoMessageService.createMessage(error, 'error')
            }
        })
    }

    selectWorkflow(workflow: WorkflowModel, workflows: WorkflowModel[]) {
        const found = workflows.find(w => w.uuid === workflow.uuid);
        if (!found) {
            this.handleAddWorkflowToList(workflow, workflows);
        } else {
            if (workflow.isStandard) {
                this.openConfirmStandardModal('Achtung', 'Hierbei handelt es sich um einen Standard-Arbeitsschritt. Möchten Sie diesen wirklich löschen?', workflow, workflows);
            } else {
                this.handleDeleteWorkflowFromList(workflow, workflows);
            }
        }
    }

    handleDeleteWorkflowFromList(workflow: WorkflowModel, workflows: WorkflowModel[]) {
        const index = workflows.findIndex(w => w.uuid === workflow.uuid);
        workflows.splice(index, 1);
        const foundIndexInCopy = this.workflowsCopy.findIndex(w => w.uuid === workflow.uuid);
        if (foundIndexInCopy !== -1) {
            this.workflows.splice(foundIndexInCopy, 0, workflow);
        }
        this.setIndex(workflows);
    }

    handleAddWorkflowToList(workflow: WorkflowModel, workflows: WorkflowModel[]) {
        workflows.push(workflow);
        const index = this.workflows.findIndex(w => w.uuid === workflow.uuid);
        this.workflows.splice(index, 1);

        // Sort the workflows to ensure isStandard are always at the bottom
        workflows.sort((a, b) => {
            if (a.isStandard && !b.isStandard) {
                return 1;
            } else if (!a.isStandard && b.isStandard) {
                return -1;
            } else {
                return a.sortIndex - b.sortIndex; // Preserve the original order for non-standard workflows
            }
        });

        this.setIndex(workflows);
    }

    setIndex(workflows: WorkflowModel[]) {
        workflows.forEach((wfl, index) => {
            wfl.sortIndex = index;
        })
    }

    addDraftToProduction(draft: DraftModel) {
        this.draftService.getDraft(draft.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponse<DraftModel>) => {
                    const uuidsOfResponse = new Set(response.data.workflows.map(wfl => wfl.uuid));
                    const hasDuplicates = this.selectedWorkflows.some(wfl => uuidsOfResponse.has(wfl.uuid));
                    if (hasDuplicates) {
                        let nonDuplicatedWorkflows: WorkflowModel[] = [];
                        response.data.workflows.forEach(wfl => {
                            const isDuplicate = this.selectedWorkflows.some(w => w.uuid === wfl.uuid);
                            if (!isDuplicate) {
                                nonDuplicatedWorkflows.push(wfl);
                            }
                        })
                        if (nonDuplicatedWorkflows.length === 0) {
                            this.infoMessageService.createMessage("Alle Arbeitschritte dieser Vorlage sind bereits im Produktionsablauf vorhanden.", 'error');
                            return;
                        } else {
                            this.openNonDuplicatedWorkflowsDialog(nonDuplicatedWorkflows);
                            return
                        }
                    }
                    response.data.workflows.forEach(wfl => {
                        const found = this.selectedWorkflows.find(w => w.uuid === wfl.uuid);
                        if (!found) {
                            this.selectWorkflow(wfl, this.selectedWorkflows)
                        }
                    })
                },
                error: error => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    isSelected(workflow: WorkflowModel): boolean {
        return this.selectedWorkflows.some(selectedWorkflow => selectedWorkflow.uuid === workflow.uuid);
    }

    drop(event: CdkDragDrop<WorkflowModel[]>, workflows: WorkflowModel[]) {
        moveItemInArray(this.selectedWorkflows, event.previousIndex, event.currentIndex);
        workflows.forEach((wfl, index) => {
            wfl.sortIndex = index;
        })
    }

    loadSubcategoriesByCategoryUuid(category: CategoryModel) {
        const uuid = category.uuid;
        if (!uuid) {
            this.subCategories = [];
            return;
        }

        this.categoryService.getSubCategories(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<SubcategoryModel>) => {
                    this.nestedCategories.push({
                        category: category,
                        subCategories: response.data
                    })
                },
                error: error => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    loadWorkflowsByCategoryUuid() {
        const uuid = this.subcategoryForm.get('uuid')?.value;
        if (!uuid) {
            this.workflows = [];
            return;
        }

        this.workflowService.getWorkflowListBySubCategory(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<WorkflowModel>) => {
                    const selectedWorkflows = response.data.filter(workflow => this.isSelected(workflow));
                    this.workflows = response.data.filter(workflow => !selectedWorkflows.includes(workflow));
                    this.workflowsCopy = [...response.data];
                },
                error: error => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    submitProduction() {
        this.productionModel = {
            uuid: '',
            orderNumber: this.component.orderNumber,
            shaftNumber: this.component.shaftNumber,
            coordinatesUuid: '',
            workflowUuids: this.selectedWorkflows.map(workflow => workflow.uuid),
            workflows: this.selectedWorkflows,
            buildingSite: this.component.buildingSite,
            company: this.component.company
        }
        this.addProduction();
    }


    private loadDrafts() {
        this.draftService.getDrafts()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponseList<DraftModel>) => {
                    this.drafts = response.data;
                    this.draftsCopy = [...response.data];
                },
                error: error => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    private openConfirmStandardModal(title: string, message: string, workflow: WorkflowModel, workflows: WorkflowModel[]) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: title,
                message: message
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.handleDeleteWorkflowFromList(workflow, workflows)
            }
        });
    }

    private addProduction() {
        this.productionService.addProduction(this.productionModel)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: iResponse<ProductionModel>) => {
                    this.infoMessageService.createMessage('Der Auftrag wurde für die Produktion angelegt', 'success')
                    this.selectedWorkflows = [];
                    this.router.navigate(['/planning']);
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    private getWorkflow(uuid: string): Observable<WorkflowModel | null> {
        return this.workflowService.getWorkflow(uuid).pipe(
            takeUntil(this.destroy$),
            map((response: iResponse<WorkflowModel>) => response.data),
            catchError((error) => {
                this.infoMessageService.createMessage(error, 'error');
                return of(null); // Return an Observable that emits null
            })
        );
    }

    private getStandardWorkflows(): Observable<WorkflowModel[]> {
        return this.workflowService.getStandardWorkflows().pipe(
            takeUntil(this.destroy$),
            map((response: iResponseList<WorkflowModel>) => response.data),
            catchError((error) => {
                this.infoMessageService.createMessage(error, 'error');
                return of([]); // Return an Observable that emits an empty array
            })
        );
    }

    private initRouteListener() {
        this.activatedRoute.params.subscribe(params => {
            this.loadComponent(params['orderNumber'], params['shaftNumber']);
        });
    }

    private openNonDuplicatedWorkflowsDialog(workflows: WorkflowModel[]) {
        const dialogRef = this.dialog.open(NonDuplicatedWorkflowsComponent, {
            data: {
                workflows
            }
        })
        dialogRef.afterClosed().subscribe((result: WorkflowModel[]) => {
            if (result) {
                result.forEach(wfl => {
                    this.selectWorkflow(wfl, this.selectedWorkflows)
                })
            }
        })
    }

    // CAN DEACTIVATE
    canDeactivate(): CanDeactivateType {
        if (this.selectedWorkflows.length > 0) {
            const deactivateSubject = new Subject<boolean>();
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                data: {
                    title: 'Ungespeicherte Daten',
                    message: 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Nicht gespeicherte Daten gehen verloren.'
                }
            })

            dialogRef.afterClosed().subscribe(result => {
                deactivateSubject.next(result);
            });
            return deactivateSubject;
        } else {
            return true;
        }
    }
}
