import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MarkdownModule} from "ngx-markdown";

@Component({
    selector: 'app-version-modal',
    standalone: true,
    imports: [MarkdownModule],
    templateUrl: './version-modal.component.pug',
    styleUrl: './version-modal.component.sass',
})
export class VersionModalComponent {
    constructor(
        public dialogRef: MatDialogRef<VersionModalComponent>,
        @Inject((MAT_DIALOG_DATA)) public data: { version: string, info: string }
    ) {
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
