import {Component, OnInit} from '@angular/core';
import {DashboardTileComponent} from "../../../shared-components/tiles/dashboard-tile/dashboard-tile.component";
import {GeolocationService} from "../../../services/geolocation/geolocation.service";
import {Subject, takeUntil} from "rxjs";
import {VersionService} from "../../../services/version/version.service";
import {MatDialog} from "@angular/material/dialog";
import {VersionModalComponent} from "../version-modal/version-modal.component";
import {UserService} from "../../../services/user/user.service";
import {AuthenticationService} from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-administration',
  standalone: true,
  imports: [DashboardTileComponent],
  templateUrl: './administration.component.pug',
  styleUrl: './administration.component.sass'
})
export class AdministrationComponent implements OnInit {
    private destroy$ = new Subject<void>();
    versionInfo: string = '';
    infoMarkdown: string = '';
    userUuid: string = '';

    constructor(private versionService: VersionService, private dialog: MatDialog, private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.getVersionInfo();
        this.userUuid = this.authService.getUserId();
    }

    openVersionModal() {
        const dialogRef = this.dialog.open(VersionModalComponent, {
            data: {
                version: this.versionInfo,
                info: this.infoMarkdown
            }
        })

        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.setVersionRead();
            })
    }

    private getVersionInfo() {
        this.versionService.getVersionInfo()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (versionInfo) => {
                    this.versionInfo = versionInfo.version;
                    this.infoMarkdown = versionInfo.info;
                    this.getVersionByUserID();
                },
                error: (error) => {
                    console.log('error', error);
                }
            })
    }

    getVersionByUserID(): void {
        this.versionService.getVersionByUserId(this.userUuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    if (!response.data.lastVersionRead || response.data.lastVersionRead !== this.versionInfo) {
                        this.openVersionModal();
                    }
                },
                error: (error) => {
                    console.log('error', error);
                }
            })
    }

    setVersionRead(): void {
        this.versionService.setVersionByUserId(this.userUuid, this.versionInfo)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    console.log(response)
                },
                error: (error) => {
                    console.log('error', error);
                }
            })
    }
}
