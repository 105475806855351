import {AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImageModel} from "../../../models/image/image.model";

@Component({
    selector: 'app-image-modal',
    standalone: true,
    imports: [],
    templateUrl: './image-modal.component.pug',
    styleUrl: './image-modal.component.sass'
})
export class ImageModalComponent implements OnInit {

    imageModel!: ImageModel
    imageDataBase: string | null = null

    constructor(
        public dialogRef: MatDialogRef<ImageModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {image:ImageModel, showRenew: boolean},
        private zone: NgZone
    ) {

    }

    ngOnInit(): void {
        this.imageModel = this.data.image;
        this.convertImageData(this.imageModel);
    }

    convertImageData(imageModel: ImageModel): void {
        this.imageDataBase = 'data:image/png;base64,' + imageModel.imageData;
    }

    onClose(): void {
        this.dialogRef.close();
    }

    redoPic(): void {
        this.dialogRef.close('newImage');
    }
}
