import {Injectable} from "@angular/core";
import {HttpService} from "../http/http.service";
import {iResponse, iResponseList} from "../../models/response/response.model";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {UserModel} from "../../models/user/user.model";

@Injectable({providedIn: 'root'})
export class UserService extends HttpService {
    getUserList(): Observable<iResponseList<UserModel>> {
        return this.get('user').pipe(
            map((response: iResponseList<UserModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    updateUser(user: UserModel): Observable<iResponse<UserModel>> {
        return this.put(`user`, user).pipe(
            map((response: iResponse<UserModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    addUser(user: UserModel): Observable<iResponse<UserModel>> {
        return this.post(`user`, user).pipe(
            map((response: iResponse<UserModel>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    changePassword(userID: string, password: string): Observable<iResponse<boolean>> {
        return this.put(`user/${userID}/password`, {password}).pipe(
            map((response: iResponse<boolean>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }

    deleteUser(userID: string): Observable<iResponse<boolean>> {
        return this.delete(`user/${userID}`).pipe(
            map((response: iResponse<boolean>) => {
                return response;
            }),
            catchError(error => {
                throw error
            })
        )
    }
}


