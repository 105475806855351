import {Component, OnDestroy, OnInit} from '@angular/core';
import {WorkflowModel} from "../../../models/workflow/workflow.model";
import {WorkflowService} from "../../../services/workflow/workflow.service";
import {InfoMessageService} from "../../../services/infomessage/infomessage.service";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {CategoryService} from "../../../services/category/category.service";
import {Subject, takeUntil} from "rxjs";
import {CanDeactivateType} from "../../../guards/unsaved-changes/unsaved-changes.guard";
import {AddContentModalComponent} from "../add-content-modal/add-content-modal.component";
import {RoundedButtonComponent} from "../../../shared-components/buttons/rounded-button/rounded-button.component";

@Component({
    selector: 'app-workflow',
    standalone: true,
    imports: [ReactiveFormsModule, MatCheckboxModule, RouterLink,RoundedButtonComponent],
    templateUrl: './workflow.component.pug',
    styleUrl: './workflow.component.sass'
})
export class WorkflowComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    workflowList: WorkflowModel[] = [];
    filteredWorkflowList: WorkflowModel[] = [];

    addWorkflowBool: boolean = false;
    filterForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
    })
    subcategoryUuid: string = '';
    subcategoryName: string = '';
    filterTimeout: any;


    workflowForm: FormGroup = new FormGroup<any>({
        name: new FormControl<string>(''),
        uuid: new FormControl<string>(''),
        coordinates: new FormControl<boolean>(false),
        image: new FormControl<boolean>(false),
        categoryUuid: new FormControl<string>(''),
        restrictions: new FormControl<string>(''),
    })

    constructor(
        private workflowService: WorkflowService,
        private dialog: MatDialog,
        private infoMessageService: InfoMessageService,
        private activatedRoute: ActivatedRoute,
        private categoryService: CategoryService
    ) {
    }

    ngOnInit() {
        this.initParamListener();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openAddContentModal() {
        const dialogRef = this.dialog.open(AddContentModalComponent, {
            data: {
                title: 'Arbeitsschritt hinzufügen',
                message: 'Bitte geben Sie einen Namen ein',
                placeholder: 'Arbeitsschrittname',
                type: 'workflow',
                uuid: this.subcategoryUuid
            }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.type === 'selected') {
                    // TODO Endpoint which add uuids to nm table
                    // this.workflowForm.get('name')?.patchValue(result.name);
                    // this.addNewWorkflow();
                } else if (result.type === 'name') {
                    this.workflowForm.get('name')?.patchValue(result.selectedOption);
                    this.addNewWorkflow();
                }
            }
        })
    }


    filterList(listToFilter: WorkflowModel[]) {
        const filterValue = this.filterForm.get('name')?.value.toLowerCase();
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            this.filteredWorkflowList = listToFilter.filter(workflow => workflow.name.toLowerCase().includes(filterValue));
        }, 500)
    }

    activateEditWorkflow(workflow: WorkflowModel) {
        if (this.workflowForm.get('uuid')?.value !== workflow.uuid) {
            this.workflowForm.get('uuid')?.patchValue(workflow.uuid);
            this.workflowForm.get('name')?.patchValue(workflow.name);
            this.workflowForm.get('coordinates')?.patchValue(workflow.coordinates);
            this.workflowForm.get('image')?.patchValue(workflow.image);
            if (workflow.restrictions) {
                this.workflowForm.get('restrictions')?.patchValue(workflow.restrictions);
            } else {
                this.workflowForm.get('restrictions')?.patchValue('');
            }
        } else {
            this.workflowForm.get('uuid')?.patchValue('');
            this.workflowForm.get('name')?.patchValue('');
            this.workflowForm.get('coordinates')?.patchValue(false);
            this.workflowForm.get('image')?.patchValue(false);
            this.workflowForm.get('restrictions')?.patchValue('');
        }
    }

    addNewWorkflow() {
        this.workflowService.addWorkflow(this.workflowForm.value, this.subcategoryUuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.workflowList.push(response.data);
                    this.filteredWorkflowList = [...this.workflowList];
                    this.resetWorkflowForm();
                    this.scrollToBottom();
                    this.infoMessageService.createMessage('Arbeitsschritt hinzugefügt', 'success');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    updateWorkflow() {
        this.workflowService.updateWorkflow(this.workflowForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    let foundIndex = this.workflowList.findIndex(workflow => workflow.uuid === this.workflowForm.get('uuid')?.value);
                    this.workflowList[foundIndex] = response.data;
                    this.filteredWorkflowList = [...this.workflowList];
                    this.resetWorkflowForm();
                    this.infoMessageService.createMessage('Arbeitsschritt aktualisiert', 'success');
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error')
                }
            })
    }

    resetWorkflowForm() {
        this.workflowForm.reset();
        this.workflowForm.get('uuid')?.patchValue('');
        this.workflowForm.get('name')?.patchValue('');
        this.workflowForm.get('coordinates')?.patchValue(false);
        this.workflowForm.get('image')?.patchValue(false);
    }


    openDeleteWorkflow(workflow: WorkflowModel) {
        this.openConfirmDialog('Löschen', 'Wollen Sie den Arbeitsschritt "' + workflow.name + '" wirklich löschen?', workflow, 'delete');
    }

    private triggerGetWorkflowListBySubCategory(uuid: string) {
        this.workflowService.getWorkflowListBySubCategory(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe( {
                next: (response) => {
                    this.workflowList = [...response.data];
                    this.filteredWorkflowList = [...response.data];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private triggerGetCategoryByUuid(uuid: string) {
        this.categoryService.getSubCategoryByUuid(uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe( {
                next: (response) => {
                    this.subcategoryUuid = response.data.uuid;
                    this.subcategoryName = response.data.name;
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private openConfirmDialog(title: string, message: string, workflow: WorkflowModel, type: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: title,
                message: message
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (type === 'delete') {
                    this.triggerDeleteWorkflow(workflow);
                }
            }
        });
    }

    private triggerDeleteWorkflow(workflow: WorkflowModel) {
        this.workflowService.deleteWorkflow(workflow.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response) => {
                    this.infoMessageService.createMessage('Der Arbeitsschritt ' + workflow.name + ' wurde gelöscht');
                    let foundIndex = this.workflowList.findIndex(c => c.uuid === workflow.uuid);
                    this.workflowList.splice(foundIndex, 1);
                    this.filteredWorkflowList = [...this.workflowList];
                },
                error: (error) => {
                    this.infoMessageService.createMessage(error, 'error');
                }
            })
    }

    private initParamListener() {
        this.activatedRoute.params.subscribe(params => {
            if (params['uuid']) {
                this.subcategoryUuid = params['uuid'];
                this.workflowForm.get('categoryUuid')?.patchValue(this.subcategoryUuid);
                this.triggerGetWorkflowListBySubCategory(params['uuid']);
                this.triggerGetCategoryByUuid(params['uuid']);
            }
        });
    }

    private scrollToBottom() {
        setTimeout(() => {
            const element = document.querySelector('section');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        },500)
    }

    // CAN DEACTIVATE
    canDeactivate(): CanDeactivateType {
        if (this.workflowForm.dirty) {
            const deactivateSubject = new Subject<boolean>();
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                data: {
                    title: 'Ungespeicherte Daten',
                    message: 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Nicht gespeicherte Daten gehen verloren.'
                }
            })

            dialogRef.afterClosed().subscribe(result => {
                deactivateSubject.next(result);
            });
            return deactivateSubject;
        } else {
            return true;
        }
    }
}
