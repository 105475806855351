import {Component} from '@angular/core';
import {DashboardTileComponent} from "../../../shared-components/tiles/dashboard-tile/dashboard-tile.component";

@Component({
  selector: 'app-archive',
  standalone: true,
  imports: [DashboardTileComponent],
  templateUrl: './archive.component.pug',
  styleUrl: './archive.component.sass'
})
export class ArchiveComponent{
    constructor(
    ) {
    }

}
